.mb--20 {
  margin-bottom: -20px;
}
.protean-ecabinet {
  padding: 34px 0px 40px;
  margin-bottom: 44px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #fff;
  .share-error {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
    text-align: center;
    .main-text {
      font-size: 14px;
      font-weight: 500;
      color: #24272a;
      margin-top: 8px;
    }
    .extra-text {
      font-size: 14px;
      color: #24272a;
    }
    .or-text {
      margin: 16px 0;
    }
    .protean-link {
      font-size: 14px;
      font-weight: bold;
      color: #3f66d4;
      margin-left: 12px;
    }
  }
  .shared-till {
    padding-left: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    color: #24272a;
  }

  .folder-title {
    padding-left: 16px;
    padding-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    color: #24272a;
  }
}
.cabinet-view-modal-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  .fix-spinner {
    width: 100%;
  }
  iframe {
    border: none;
  }
}
.cabinet-table {
  .cabinet-actions {
    padding: 0px 16px 16px 16px;
    display: flex;
    position: relative;
    &.Shared {
      .download {
        margin-left: 0;
      }
    }
    .upload {
      margin-left: 10px;
    }
    .download {
      margin-left: 10px;
      height: 39px;
      border-radius: 4px;
      background-color: #578ef7;
      .download-icon {
        fill: #fff;
      }
    }
    .search-box {
      display: flex;
      align-items: center;
      min-width: 41px;
      .search-type {
        display: none;
      }
      section {
        min-width: 40px;
        input {
          width: 40px;
          margin: 0;
        }
      }

      &.open {
        width: 100%;
        flex-wrap: wrap;
        .search-type {
          display: flex;
          flex: 1;
          margin-top: 15px;
        }

        section {
          width: calc(100% - 55px);
          display: flex;
          input {
            width: -webkit-fill-available;
          }
          img {
            opacity: 0.5;
          }
        }
      }
      .search-close {
        display: flex;
        outline: none !important;
        &.hide {
          display: none;
        }
        padding-left: 10px;
        padding-right: 0;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: bold;
        color: #3f66d4;
      }
    }

    .pagination-size {
      margin-left: auto;
      display: flex;
      align-items: center;
      .protean-select {
        min-width: 78px !important;
        img {
          top: 12px;
        }
      }
    }
  }
  .folder-path {
    padding-left: 16px;
    padding-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    img {
      margin-top: 10px;
    }
    .folder-path-link {
      font-size: 13px;
      font-weight: bold;
      color: #3a4669;
      padding: 6px 8px;
      height: 31px;
      margin-top: 8px;
      border-radius: 4px;
      background-color: #f7f9fa;
    }
  }
}

.share-modal {
  padding: 0;
  width: 100%;
  .close-btn {
    top: 22px;
    right: 20px;
  }
  .base-modal {
    .card-header {
      padding: 20px;
      padding-bottom: 32px;
    }
    .card-body {
      padding: 20px;
      .protean-input-with-button {
        display: flex;
        flex-direction: column;
        &.email-box {
          .email-box-content,
          .email-box-header {
            margin-top: 19px;
          }
        }
        .input-title {
          font-size: 13px;
          font-weight: 500;
          color: #a4a8b1;
          margin-bottom: 8px;
        }
        .input-group-append {
          display: flex;
          justify-content: space-between;
          .protean-btn,
          .protean-btn-default {
            width: 48%;
            height: 38px;
            z-index: 1;
            &.send {
              width: 100%;
            }
          }
        }
        input.input-with-button {
          &.is-invalid {
            background: transparent;
            border-color: #f6a4a4;
          }
          font-size: 13px;
          font-weight: bold;
          color: #616674;
          width: 100%;
          height: 38px;
          border-radius: 4px;
          border: solid 1px #eaeeef;
          background-color: #ffffff;
          margin-bottom: 12px;
        }
      }
    }
  }
}
.cabinet-table-content {
  position: relative;
  .loading-block {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 100%;
    height: calc(100% + 40px);
  }

  .cabinet-table-header {
    height: 32px;
    padding: 8px 0px 8px 15px;
    border-radius: 4px;
    background-color: #f7f9fa;
    font-size: 12px;
    font-weight: bold;
    color: #616674;
    .th {
      &.selection {
        // min-width: 28px !important;
        padding-right: 14px;
      }
    }
  }
  .cabinet-table-body {
    .no-data-block {
      display: flex;
      justify-content: center;
      padding: 20px;
    }
    padding-bottom: 50px;
    .tr {
      border-bottom: 1px solid #eaeeef;
      padding: 16px;

      align-items: center;
      .td {
        &.selection {
          // min-width: 28px !important;
          padding-right: 14px;
        }
        .file-link {
          display: flex;
          padding-right: 14px;
          font-size: 14px;
          color: #24272a;
          word-break: break-word;
          .svg-fill-icon {
            transform: translateY(1px);

            min-width: 18px;
            display: flex;
            margin-right: 6px;
            fill: #616674;
          }
        }
      }
    }
  }
}

.protean-reports {
  padding: 8px 8px 80px 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #fff;
  .main-title {
    padding: 16px 8px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
    &-sub {
      padding-top: 0;
      padding-bottom: 32px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
    }
  }
  .report-field {
    margin-bottom: 24px;
    .field-title {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #a4a8b1;
      margin-bottom: 8px;
      &.sub {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #a4a8b1;
      }
    }
    .year-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .protean-input {
        //flex: 6
        margin-right: 16px;
      }
      .protean-btn {
        touch-action: manipulation;
        &:last-of-type {
          margin-right: 0;
        }
        min-width: 40px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .sheet-types {
      .sheet-types-check {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
        .type-title {
          width: fit-content;
          padding: 6px 10px;
          border-radius: 4px;
          background-color: #f7f9fa;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          margin-bottom: 12px;
        }
        .checkbox-filter {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          margin-bottom: 12px;
        }
      }
    }
  }
  .keyword-hint {
    padding: 16px 14px 8px 16px;
    border-radius: 4px;
    background-color: #f7f9fa;
    ul {
      padding-left: 16px;
    }
    .hint-row {
      &:last-of-type {
        margin-bottom: 8px;
      }
      &::marker {
        font-size: 20px;
      }
      li::marker {
        font-size: 20px;
      }
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #616674;
      ul > li {
        margin-top: 8px;
      }
      margin-bottom: 24px;
      code {
        padding: 3px 12px;
        border-radius: 4px;
        background-color: #fff;
        font-family: 'PT Mono', monospace;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #616674;
      }
    }
  }
  .generate-report-btn {
    margin-top: 11px;
    width: 100%;
  }
  .divider {
    margin-top: 40px;
    margin-bottom: 30px;
    height: 5px;
    width: calc(100% + 16px);
    background-color: #f7f9fa;
    margin-left: -8px;
  }
  .col-block {
    .report-doc-section {
      padding: 0 8px;
    }
    .faq-sub-title {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
    }
    .col-block__trigger {
      height: 40px;
      cursor: pointer;
      width: -moz-available;
      padding: 8px 0px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      display: flex;
      align-items: center;
      &:before {
        height: 24px;
        width: 24px;
        background-size: cover;
        background-image: url("../images/arrow-down.svg");
        transform: rotate(-90deg);
        content: '';
        transition: transform 0.5s ease;
      }
      &.is-disabled {
        background-color: #a4a8b1;
      }
      &.is-open:before {
        transform: rotate(0deg);
      }
    }
    .col-block__contentOuter {
      .col-block__contentInner {
        .logs {
          margin-top: 16px;
          padding-top: 0;
          display: flex;
          flex-direction: column;
          position: relative;
          .log-item {
            position: relative;
            margin-bottom: 32px;
            padding-right: 8px;
            margin-left: 50px;
            width: auto;
            display: flex;
            flex-direction: column;
            &:last-of-type {
              margin-bottom: 0;
            }
            .log-circle {
              position: absolute;
              border-radius: 50%;
              left: -20px;
              top: 1px;
              width: 13px;
              height: 13px;
              border: solid 2px #7a86a9;
              background-color: #ffffff;
            }

            .log-header {
              display: flex;
              font-family: Roboto;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #a4a8b1;
              .log-delimiter {
                margin: 0 5px;
              }
            }
            .log-comment {
              margin-top: 8px;
              display: flex;
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
            }
          }
        }
        .details {
          .item {
            &.vertical {
              display: flex;
              flex-direction: column;

              .line {
                height: 1px;
                width: 100%;
                background-color: #f7f9fa;
              }
            }
            margin-top: 16px;
            display: flex;
            padding: 0 14px;
            margin-bottom: 25px;
            .item-title {
              width: 150px;
              font-family: Roboto;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
            }
            .item-value {
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #24272a;
              display: flex;
              flex-direction: row;
              .issue-photo-cell {
                width: 48px;
                height: 48px;
                margin-right: 4px;
              }
            }
          }
        }
        .occurrences {
          .row {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
            margin: 8px;
            padding: 8px 12px 8px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 4px;
            background-color: #f7f9fa;
          }
        }
        .group-details {
          .details-head {
            height: 32px;
            padding: 9px 16px 9px 16px;
            border-radius: 4px;
            background-color: #f7f9fa;
            display: flex;
            flex-direction: row;
            .item-head {
              flex: 1;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
            }
          }
          .details-body {
            .details-item {
              min-height: 64px;
              padding: 0 17px;
              display: flex;
              flex-direction: row;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #24272a;
              border-bottom: #eaeeef solid 1px;
              .details-item-title {
                flex: 0.5;
                display: flex;
                align-items: center;
              }
              .details-item-val {
                flex: 0.5;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .issue-photo-cell {
                  width: 48px;
                  height: 48px;
                  margin-right: 4px;
                }
              }
            }
            .sub-details {
              position: relative;
              .sub-details__trigger {
                height: 28px;
                width: 28px;
                display: flex;
                padding: 2px;
                border-radius: 4px;
                background-color: #f7f9fa;
                position: absolute;
                top: -47px;
                right: 16px;
                &:before {
                  height: 24px;
                  width: 24px;
                  background-size: cover;
                  background-image: url("../images/plus.svg");
                  transform: rotate(-90deg);
                  content: '';
                  transition: transform 0.5s ease;
                }
                &.is-disabled {
                  background-color: #a4a8b1;
                }
                &.is-open:before {
                  transform: rotate(0deg);
                  background-image: url("../images/minus.svg");
                }
              }
              .sub-details__contentOuter {
                .sub-details__contentInner {
                  .details-item {
                    &.horizontal {
                      flex-direction: column;
                      .details-item-title, .details-item-val {
                        width: 100%;
                        margin-bottom: 10px;
                        .issue-photo-cell {
                          width: 48px;
                          height: 48px;
                          margin-right: 4px;
                        }
                      }
                    }
                    border-bottom: none;
                    min-height: 40px !important;
                    &:last-of-type {
                      border-bottom: #eaeeef solid 1px;
                      padding-bottom: 10px;
                    }
                    .details-item-title {
                      font-size: 12px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #616674;
                      width: 50%;
                    }
                    .details-item-val {
                      width: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


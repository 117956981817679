.tags {
  &.tooltip-tag {
    max-width: 315px;

  }

  width: fit-content;

  // height: 424px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #77972c;

  .label {
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: #f1ffd0;
    padding: 5px 12px;
    display: inline-block;
    text-transform: uppercase;
  }
  .label:not(:last-child) {
    margin-right: 8px;
  }
}
.protean-tooltip-tag {
  border-radius: 4px !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #616674 !important;
  padding: 8px 8px 0 8px !important;
// width: fit-content;
  &.show {
    opacity: 1 !important;
    margin-right:30px !important;
    margin-left: 30px !important;
  }
  &:after {
    border: none !important;
  }
}
.tags-wrapper {
  &.inline {
    margin-top: 24px;
  }
  svg {
    min-width: 15px;
    min-height: 19px;
  }
  svg:hover {
    path {
      stroke: #616674;
    }
  }
}

.tags-label {
  margin-top: 24px;
  .protean-link {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #578ef7;
    margin-left: 12px;
  }
}
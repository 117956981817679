.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mr-4 {
  margin-right: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}


.mr-32 {
  margin-right: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pr-8 {
  padding-right: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}
.protean-wo {
  margin-bottom: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  .wo-td, .wo-th {
    &.label {
      justify-content: flex-start !important;
    }
  }
  .table-header {
    padding-left: 8px;
  }
  .divider {
    height: 5px;
    width: 100%;
    background-color: #f7f9fa;
  }
  .default-block {
    &:nth-of-type(1) {
      padding-top: 26px;
    }
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 8px;
    .title {
      margin-left: 8px;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      color: #24272a;
      &.main {
        font-size: 20px;
      }
    }
    .sub-title {
      padding-top: 16px;
      margin-left: 8px;
      font-size: 14px;
      color: #616674;
    }
    .fetch-filters {
      &.true.fix-picker {
        .Collapsible__contentOuter {
          padding-bottom: 55px;
        }
      }
      margin-top: 32px;
      .fetch-filter {
        margin-top: 16px;
        width: 100%;
        .filter-title {
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a4a8b1;
        }
      }
      .fetch-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .protean-btn {
          width: 151px;
        }
      }
    }
  }
  .mobile-table {
    margin: 32px 8px 32px;
    .extra {
      display: flex;
      flex-direction: column;
      .pagination-size {
        display: flex;
        align-items: center;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        padding-right: 30px;
      }
      .export-download-dropdown {
        display: flex;
        position: relative;
        button {
          min-width: 150px !important;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          border: solid 1px #eaeeef !important;
          background-color: #578ef7 !important;
          &.dropdown-toggle:after {
            margin-left: auto;
          }
          &:hover {
            background-color: #3c6ecd !important;
            color: #fff;
          }
          &.reportBtn {
            height: 39px;
            border-radius: 4px !important;
            border: solid 1px #eaeeef;
            background-color: #578ef7;
          }
          &.dropdown-item {
            border: none !important;
            padding-left: 10px;
            padding-right: 0;
            background-color: #578ef7;
            min-width: 198px !important;
          }
          &.dropdown-item:hover {
            background-color: #3c6ecd !important;
            color: #fff;
          }
        }
        .dropdown-menu {
          min-width: 200px !important;
          top: 100%;
          background-color: #578ef7 !important;
          border-radius: 4px !important;
          border: solid 1px #eaeeef;
          margin-top: 0;
        }
      }
    }
  }
  .protean-table {
    margin: 32px 0;

    .table-header {
      border-radius: 4px;
      background-color: #f7f9fa;
      height: 32px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;

      .th-cell {
        display: flex;
        justify-content: center;
        align-items: center;

        &.created_on {
          justify-content: left !important;
        }
      }
    }

    .table-body {
      .body-row {
        min-height: 48px;
        height: auto;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;
        padding: 0 8px;
        border-radius: 4px;
        background-color: #f7f9fa;
        margin-top: 8px;

        &.expanded {
          background-color: #eaeeef;
        }

        &:nth-of-type(1) {
          margin-top: 4px;
        }

        .expand-btn {
          &:focus {
            outline: none;
          }

          margin-left: 10px;
          padding: 5px;
          border-radius: 4px;
          background-color: #f7f9fa;
        }
      }
      .extra-cell {
        margin-top: 1px;
        background-color: #eaeeef;
        //height: 95px;
        display: flex;
        flex-direction: column;
        &.sub {
          height: auto;
          background-color: transparent;
          .cell {
            height: auto;
            margin: 8px 0;
            display: flex;
            align-items: baseline;
            span:nth-of-type(1) {
              margin-left: 15px;
              min-width: 100px;
              max-width: 100px;
            }
            span:nth-of-type(2) {
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #24272a;
            }
          }
        }
        .go-to-wo {
          padding: 0;
          align-items: center;
          display: flex;
          margin: 7px;
          width: -fill-available;
        }
        .cell {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #616674;
          display: flex;
          align-items: center;
          height: 47px;
          span:nth-of-type(1) {
            margin-left: 15px;
            width: 110px;
          }
        }
      }
      .mobile-sub-table {
        margin-top: 5px;
      }

      .protean-sub-table {
        margin-top: 5px;

        .table-header {
          border-radius: 4px;
          margin: 0 5px;
          background-color: #f7f9fa;
          height: 32px;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #616674;

          .th-cell {
            display: flex;
            justify-content: center;
            align-items: center;

            &.created_on {
              justify-content: left !important;
            }
          }
        }

        .body-row {
          min-height: 60px;
          height: auto;
          border-bottom: solid #eaeeef 1px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          padding: 0 8px;
          border-radius: 0;
          background-color: transparent;
          margin-top: 8px;
          &:last-of-type {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}

.protean-select {
  position: relative;
  img {
    position: absolute;
    right: 16px;
    top: 10px;
  }
  .selected-text {
    margin: 0 8px;
    width: inherit;
    padding: 8px 12px;
    appearance: none;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
    span {
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #a4a8b1;
    }
  }
  .select-options {
    position: absolute;
    width: calc(100% - 16px);
    left: 8px;
    top: 0;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeeef;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    z-index: 9;
    li {
      &:first-of-type {
        margin-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        margin-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &.active {
        background-color: #f7f9fa;
      }
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;

      margin: 4px 0;
      list-style-type: none;
      padding: 8px 12px;
      cursor: pointer;
    }

    li:hover {
      background-color: #f7f9fa;
    }
  }
}

.mt-32 {
  margin-top: 32px;
}

.mr-11 {
  margin-right: 11px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-21 {
  margin-left: 21px;
}

.mr-21 {
  margin-right: 21px;
}

.protean-badge {
  padding: 5px 9px;
  border-radius: 15px;
  background-color: #f7f9fa;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #a4a8b1;
  &.main {
    background-color: #eaeeef;
  }
  .crc {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #bcd87a;
  }
}

.dot {
  display: flex;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  &-1 {
    background-color: #f7f9fa;
    .crc {
      background-color: #dc6e6e;
    }
  }
  &-2 {
    background-color: #f7f9fa;
    .crc {
      background-color: #efc675;
    }
  }
  .crc {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}

.reset-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.protean-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #3f66d4;
}

.protean-pagintaion {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  .arrow {
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    padding: 0;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: #f0f2f4;
    }
    &.active {
      background-color: #f0f2f4;
    }
    &:disabled {
      &:hover {
        background-color: initial;
      }
      opacity: 0.5;
    }
  }
  .pagination-button {
    min-width: 32px;
    height: 32px;
    font-size: 14px;
    color: #24272a;
    border: none;
    background-color: transparent;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: #f0f2f4;
    }
    &.active {
      background-color: #f0f2f4;
    }
  }
}

.select-loading-indicator {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .spinner-border {
    width: 1rem;
    height: 1rem;
    color: #a4a8b1;
  }
}

.no-records {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24272a;
}

//.protean-tooltip {
//  width: 462px;
//  // height: 424px;
//  padding: 14px;
//  font-family: Roboto;
//  font-size: 13px;
//  font-weight: normal;
//  font-stretch: normal;
//  font-style: normal;
//  line-height: 1.54;
//  letter-spacing: normal;
//  color: #616674;
//  .protean-btn.icon {
//    &:first-of-type {
//      padding-top: 4px;
//    }
//    padding: 6px;
//  }
//  ul {
//    padding: 0;
//    margin: 0;
//    & > li {
//      font-size: 20px;
//      margin-left: 19px;
//      margin-bottom: 5px;
//      line-height: 10px;
//      span {
//        font-size: 13px;
//      }
//    }
//  }
//  .title-tooltip {
//    font-size: 13px;
//    font-weight: 900;
//    color: #616674;
//  }
//}
.protean-tooltip {
  border-radius: 4px !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #616674 !important;
  padding: 10px !important;
  width: 80vw;
  &.show {
    opacity: 1 !important;
  }
  &:after {
    border: none !important;
    background-color: transparent !important;
  }
}

.mr-16 {
  margin-right: 16px;
}

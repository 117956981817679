@import "assets/scss/index.scss";

div#root {
  min-height: calc(100vh - 3.12503rem);
  display: flex;
  flex-direction: column;
  flex: 1;
}

a {
  cursor: pointer;
}

.display-block {
  display: block!important;
}

.alarms {
  .dropdown-toggle-alrm {
    place-content: space-between;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .alarms-padding {
    padding: 8px;
  }
  .alarm-filters {
    flex-wrap: wrap;
    justify-content: space-between;
    .filters-icon {
      margin-right: 8px;
    }
    .clear-filter-button {
      width: 94px;
      padding: 13px 16px 12px;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3a4669;
      background-color: #f7f9fa;
    }
  }
  .active-pagesize-wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 8px;
    .pagination-size {
      align-items: center;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      padding-right: 30px;
      flex: 70px 1 0;
    }
  }
  .list-title {
    background-color: #f7f9fa;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #616674;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
  }

  .sub-row {
    display: none;
    &.open {
      display: flex;
    }
    border-top: 1px #eaeeef solid;
    margin: 0 16px;

    flex-wrap: wrap;
    .title {
      margin-top: 21px;
      width: 40%;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
      align-items: center;
      display: flex;
    }
    .value {
      margin-top: 21px;
      width: 60%;
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      align-items: center;
      display: flex;
      &.buttons {
        display: flex;
        .generate-btn {
          height: 32px;
          &.active {
            background-color: #86aefb;
            height: 32px;
            color: #fff;
            cursor: default;
            display: flex;
            padding: 0 16px;
            align-items: center;
            .loading-indicator {
              animation: rotation 1s linear infinite;
              margin-right: 8px;
            }
          }
        }
        .view,
        .download {
          padding: 0;
          margin: 0 4px;
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg > g > path {
            fill: #616674;
          }
        }
      }
    }
  }
  .value-row {

    display: flex;
    font-family: Roboto;
    font-size: 13px;
    line-height: normal;
    color: #24272a;
    padding: 16px 0px;
    .value {

      .expand-btn {
        width: 28px;
        height: 28px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          fill: #616674;
        }
      }
      &.command {
        padding-left: 16px;
        flex: 6;
      }

      flex: 3;
      &.code {
        flex: 1;
      }
      &.actions {
        flex: 2;
      }

      display: flex;
      padding: 0 8px;
      align-items: center;
      &.hide {
        flex: 1;
        place-content: flex-end;
        margin-right: 8px;
      }
    }
  }
  .row-devider {
    width: 100%;
    border-bottom: 1px #eaeeef solid;
  }
  .protean-link {
    font-size: 13px;
    text-align: left;
  }
}

.wo-badge {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a4a8b1;
  padding: 5px 9px;
  border-radius: 15px;
  background-color: #eaeeef;
}

.wo-badge-status-oval {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;

  &.with-text {
    margin-right: 5px;
  }

  &.green {
    background-color: #bcd87a;
  }

  &.red {
    background-color: #dc6e6e;
  }

  &.yellow {
    background-color: #efc675;
  }

  &.grey {
    background-color: #a4a8b1;
  }

  
}
.fetch-filter {
  flex: 0 0 300px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a4a8b1;

  .custom-picker {
    min-width: 284px !important;
    padding: 0px !important;
    left: -1px !important;
    // width: 284px !important;
    .datepicker-months {
      .datepicker-block {
        width: 303px !important;
        .datepicker-week-day {
          width:43.2px!important;
        }
        .datepicker-day {
          width:43.2px!important;
        }
        .datepicker-nav-button:nth-child(1) {
          padding-left: 8px !important;
        }
        .datepicker-nav-button:nth-child(3) {
          padding-right: 8px !important;
        }
        .datepicker-nav {
          padding-top: 26px !important;
        }
      }
    }
  }
}
.fetch-filter:not(:last-child) {
  margin-right: 8px;
}

input.single-input {
  padding: 0!important;
}

.datepicker-block {
  input.single-input {
    padding-left: 10px!important;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a4a8b1;
  
  }
}
input.single-input::placeholder {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a4a8b1;
  padding-left:10px
}
.date-filter {
  flex: 1 1 260px;
}
.clear-date-filter {
  margin-left: 12px;
  padding: 6.8px 8px;
}

.pb8 {
  padding-bottom: 8px;
}

.pt8 {
  padding-top: 8px;
}

.pl8 {
  padding-left: 8px;
}

.pr8 {
  padding-right: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt8 {
  margin-top: 8px;
}

.ml8 {
  margin-left: 8px;
}

.mr8 {
  margin-right: 8px;
}

.filters-body {
  flex-wrap: wrap;
}

.protean-wo-details {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  padding: 34px 0;
  margin-bottom: 39px;
  .px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
    padding-left: 8px;
    &.sub {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      margin-top: 23px;
      display: flex;
      align-items: center;
      margin-right: 11px;
      .issue-badge {
        padding: 5px 9px;
        border-radius: 15px;
        background-color: #f7f9fa;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #a4a8b1;
      }
    }
  }
  .site-address {
    font-size: 14px;
    font-weight: 400;
    color: #a4a8b1;
    margin-bottom: 32px;
  }
  .divider {
    &:last-of-type {
      display: none;
    }
    background-color: #f7f9fa;
    height: 5px;
    width: 100%;
    margin-top: 40px;
  }
  .facility-btn {
    display: flex;
    align-items: center;
    padding: 8px 84px;
    border-radius: 4px;
    background-color: #f7f9fa;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3a4669;
    &.issue-create-btn {
      margin-top: 10px;
      height: 40px;
    }
  }
  .actions {
    margin-bottom: 16px;
    margin-top: 12px;
  }

  .action {
    margin-top: 16px;
    .action__trigger {
      height: 40px;
      cursor: pointer;
      //margin-left: 8px;
      //margin-right: 8px;
      width: -moz-available;
      padding: 8px 16px;
      border-radius: 4px;
      background-color: #578ef7;
      &.grey {
        background-color: #f7f9fa;
        color: #3a4669;
        &.is-disabled {
          background-color: #f7f9fa;
          color: #a4a8b1;
          &:after {
            filter: brightness(5) !important;
          }
        }
        &:after {
          filter: brightness(0) !important;
        }
      }
      font-family: Roboto;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      &:after {
        margin-left: auto;
        height: 20px;
        width: 20px;
        background-image: url("../images/arrow-down.svg");
        transform: rotate(-90deg);
        content: '';
        filter: brightness(12.5);
        transition: transform 0.5s ease;
      }
      &.is-disabled {
        background-color: #a4a8b1;
      }
      &.is-open:after {
        transform: rotate(0deg);
      }
    }
    .action__contentOuter {
      .action__contentInner {
        .keys-block {
          margin-top: 16px;
          padding: 12px 21px 24px 12px;
          border-radius: 4px;
          background-color: #f7f9fa;
          .key-item {
            display: flex;
            flex-direction: row;
            margin-top: 12px;
            .key-title {
              &.true {
                background-color: transparent;
              }
              justify-content: center;
              width: 112px;
              display: flex;
              padding: 4px 27px 4px 27px;
              border-radius: 4px;
              background-color: #fff;
              font-family: Roboto;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #616674;
            }
            .key-value {
              margin-left: 16px;
              display: flex;
              flex: 1;
              align-items: center;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
            }
          }
        }
        .action-button {
          height: 40px;
          cursor: pointer;
          width: -webkit-fill-available;
          margin: 16px 16px;
          border-radius: 4px;
          background-color: #f7f9fa;
          border: none;
          padding: 13px 0;
          font-family: Roboto;
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #3a4669;
          &:disabled {
            background-color: #f0f2f4;
            color: #a4a8b1;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .col-block {
    .col-block__trigger {
      height: 40px;
      cursor: pointer;
      width: -moz-available;
      padding: 8px 16px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      display: flex;
      align-items: center;
      margin-top: 32px;

      &:before {
        height: 24px;
        width: 24px;
        background-size: cover;
        background-image: url("../images/arrow-down.svg");
        transform: rotate(-90deg);
        content: '';
        transition: transform 0.5s ease;
      }
      &.is-disabled {
        background-color: #a4a8b1;
      }
      &.is-open:before {
        transform: rotate(0deg);
      }
    }
    .col-block__contentOuter {
      .col-block__contentInner {
        .logs {
          margin-top: 16px;
          padding-top: 0;
          display: flex;
          flex-direction: column;
          position: relative;
          max-height: 206px;
          overflow-y: auto;
          .log-item {
            position: relative;
            margin-bottom: 32px;
            padding-right: 8px;
            margin-left: 50px;
            width: auto;
            display: flex;
            flex-direction: column;
            &:last-of-type {
              margin-bottom: 0;
            }
            .log-circle {
              position: absolute;
              border-radius: 50%;
              left: -20px;
              top: 1px;
              width: 13px;
              height: 13px;
              border: solid 2px #7a86a9;
              background-color: #ffffff;
            }

            .log-header {
              display: flex;
              font-family: Roboto;
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #a4a8b1;
              .log-delimiter {
                margin: 0 5px;
              }
            }
            .log-comment {
              margin-top: 8px;
              display: flex;
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
            }
          }
 
        }
        .details {
          display: flex;
          flex-direction: column;
          margin-top: 22px;
          .info-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            min-height: 30px;
            margin-top: 4px;
            .info-title {
              padding: 8px 0 8px 16px;
              display: flex;
              align-items: center;
              width: 45%;
              min-width: 108px;
              margin-right: 8px;
              border-radius: 4px;
              background-color: #f7f9fa;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
            }
            .info-val {
              padding: 7px 16px 7px 16px;
              width: -webkit-fill-available;
              border-radius: 4px;
              background-color: #f7f9fa;
              font-family: Roboto;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #24272a;
            }
          }
          .switch {
            margin-top: 40px;
            margin-bottom: 32px;
          }
        }
        .protean-table {
          .table-header {
            padding: 4px 0;
            border-radius: 4px;
            background-color: #f7f9fa;
            font-family: Roboto;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616674;
          }
          .table-body {
            .body-row {
              margin-top: 18px;
              padding-bottom: 18px;
              border-bottom: #eaeeef solid 1px;
              .expand-btn {
                padding: 5px;
                border-radius: 4px;
                background-color: #f7f9fa;
              }
            }
            .extra-cell {
              margin-top: 1px;
              padding: 18px 14px;
              display: flex;
              flex-direction: column;
              border-bottom: #eaeeef solid 1px;
              &.sub {
                height: auto;
                background-color: transparent;
                .cell {
                  height: auto;
                  margin: 8px 0;
                  display: flex;
                  align-items: baseline;
                  span:nth-of-type(1) {
                    margin-left: 15px;
                    min-width: 100px;
                    max-width: 100px;
                  }
                  span:nth-of-type(2) {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #24272a;
                  }
                }
              }
              .cell {
                font-family: Roboto;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #24272a;
                display: flex;
                align-items: center;
                min-height: 32px;
                span:nth-of-type(1) {
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #616674;
                  margin-left: 15px;
                  width: 150px;
                }
              }
            }
          }
        }
        .group-detail-info {
          .view-details {
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            width: 100%;
          }
          padding: 9px;
          .creator, .editor, .assigned {
            .user-title {
              font-family: Roboto;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #616674;
              margin-right: 8px;
            }
            .comment-text {
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.29;
              letter-spacing: normal;
              color: #616674;
            }
            .highlight {
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #24272a;
            }
          }
        }
      }
    }
  }
  .sub-row {
    .view-details {
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3a4669;
      margin: 8px;
      margin-top: 20px;
    }
    display: none;
    &.open {
      display: flex;
    }
    border-top: 1px #eaeeef solid;
    flex-wrap: wrap;
    &.groups {
      border-top: 1px #fff solid;
      background-color: #edf0f3;
    }
    .title {
      margin-top: 21px;
      width: 34%;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
      align-items: center;
      display: flex;
      &.issue-group {
        width:auto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;

      }
    }
    .value {
      margin-top: 21px;
      width: 60%;
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      align-items: center;
      display: flex;
      &.issue-group-title {
        margin-top: 0;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;
        margin-left: -6px;
        &.line-item {
          margin-left: 6px;
        }
      }
      &.issue-group {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        margin-left: 8px;
        &.comment {
          line-height: 1.29;
        }
      }
      .protean-link {
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #578ef7;
      }
      &.buttons {
        display: flex;
        .generate-btn {
          height: 32px;
          &.active {
            background-color: #86aefb;
            height: 32px;
            color: #fff;
            cursor: default;
            display: flex;
            padding: 0 16px;
            align-items: center;
            .loading-indicator {
              animation: rotation 1s linear infinite;
              margin-right: 8px;
            }
          }
        }
        .rerun {
          height: 32px;
          padding: 0 inherit;
          display: flex;
          align-items: center;
        }

        .view,
        .download {
          padding: 0;
          margin: 0 4px;
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg > g > path {
            fill: #616674;
          }
        }
      }
    }
  }
  .extra {
    margin-bottom: 17px;
    .pagination-size {
      width: 80px;
      margin-left: auto;
      margin-right: 35px;
      align-items: center;
    }
  }
  .row-devider {
    width: 100%;
    border-bottom: 1px #eaeeef solid;
    &.groups {
      border-bottom: 1px #fff solid;
    }
  }
  .value-row {
    display: flex;
    font-family: Roboto;
    font-size: 13px;
    line-height: normal;
    color: #24272a;
    padding: 16px 16px 16px 0;
    &.groups {
      background-color: #edf0f3;
    }
    .value {
      &.issue-group-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .expand-btn {
        width: 28px;
        height: 28px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          fill: #616674;
        }
      }
      &.priority {
        width: 25px;
        flex: 1;
        padding-left: 8px;
      }

      flex: 3;
      &.code {
        flex: 1;
      }
      &.actions {
        flex: 2;
      }

      display: flex;
      align-items: center;
      &.hide {
        flex: 1;
        place-content: end;
      }
      &.group-name {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;
        padding-left: 8px;
      }
    }
  }
  .product-header {
    height: 32px;
    /* padding: 9px 94px 9px 43px; */
    border-radius: 4px;
    background-color: #f7f9fa;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: #616674;
    display: flex;
    align-items: center;
    &.groups {
      margin-bottom: 3px;
    }
    &.issue-group-header {
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 11px;

    }
    .value {
      &.actions {
        flex: 2;
      }
      &.group-name {
        padding-left: 8px;
      }

      height: fit-content;
      &.hide {
        visibility: hidden;
        flex: 1;
        place-content: end;
      }
      &.priority {
        width: 25px;
      }
      &.line-item {
        width:86px;
        flex: 1 1 86px;
      }
      flex: 3;
    }
  }

  .switch {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 8px;
  }

}

.mb-16 {
  margin-bottom: 16px;
}

.protean-issues {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  padding: 34px 0;
  margin-bottom: 39px;
  .px-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .address {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a4a8b1;
    margin-bottom: 14px;
  }
  .site-address {
    font-size: 14px;
    font-weight: 400;
    color: #a4a8b1;
    margin-bottom: 14px;
    margin-top: 14px;
  }
  .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
    padding-left: 0px !important;
    margin-bottom: 14px;
    .issue-tags {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #a4a8b1;
      margin-bottom: 14px;
    }
    &.address {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #a4a8b1;
      margin-bottom: 14px;
    }
    &.wo-link {
      color: #578ef7;
      margin-top: 4px;
    }
    &.wo-label {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #578ef7 !important;
      a.wo-link {
        color: #578ef7;
      }
    }
    &.sub {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #24272a;
      display: flex;
      align-items: center;
      .issue-badge {
        padding: 5px 9px;
        border-radius: 15px;
        background-color: #f7f9fa;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #a4a8b1;
        margin-left: 11px;
      }
    }
  }
  .divider {
    &:last-of-type {
      display: none;
    }
    background-color: #f7f9fa;
    height: 5px;
    width: 100%;
    margin-top: 40px;
  }

.action {
  margin-top: 16px;
  .action__trigger {
    height: 40px;
    cursor: pointer;
    //margin-left: 8px;
    //margin-right: 8px;
    width: -moz-available;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #f0f2f4;
    &.grey {
      background-color: #f7f9fa;
      color: #3a4669;
      &.is-disabled {
        background-color: #f0f2f4;
        color: #a4a8b1;
        &:after {
          filter: brightness(5) !important;
        }
      }
      &:after {
        filter: brightness(0) !important;
      }
    }
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      height: 20px;
      width: 20px;
      background-image: url("../images/arrow-down.svg");
      transform: rotate(-90deg);
      content: '';
      filter: brightness(12.5);
      transition: transform 0.5s ease;
    }
    &.is-disabled {
      background-color: #a4a8b1;
    }
    &.is-open:after {
      transform: rotate(0deg);
    }
  }
  .action__contentOuter {
    .action__contentInner {
      .set-block {
        padding: 24px 24px 32px 24px;
        .status-block {
          height: 32px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .crc {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2.5px solid #616674;
            margin-right: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            .inner-crc {
              height: 10px;
              width: 10px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              &.active {
                background-color: #616674;
              }
            }
          }
          .status {
            padding: 8px 16px 7px;
            border-radius: 16px;
            &.active {
              background-color: #eaeeef;
            }

            font-family: Roboto;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            color: #3a4669;
          }
        }
        .protean-btn {
          margin-top: 32px;
          width: 100%;
        }
        .field-title {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #616674;
          margin-bottom: 12px;
        }
        .protean-select {
          margin-bottom: 16px;
          .selected-text {
            margin: 0;
          }
          .select-options {
            width: 100%;
            left: 0;
          }
        }
      }
      .keys-block {
        margin-top: 16px;
        padding: 12px 21px 24px 12px;
        border-radius: 4px;
        background-color: #f7f9fa;
        .key-item {
          display: flex;
          flex-direction: row;
          margin-top: 12px;
          .key-title {
            &.true {
              background-color: transparent;
            }
            justify-content: center;
            width: 112px;
            display: flex;
            padding: 4px 27px 4px 27px;
            border-radius: 4px;
            background-color: #fff;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #616674;
          }
          .key-value {
            margin-left: 16px;
            display: flex;
            flex: 1;
            align-items: center;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616674;
          }
        }
      }
      .action-button {
        height: 40px;
        cursor: pointer;
        width: -webkit-fill-available;
        margin: 16px 16px;
        border-radius: 4px;
        background-color: #f7f9fa;
        border: none;
        padding: 13px 0;
        font-family: Roboto;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3a4669;
        &:disabled {
          background-color: #f0f2f4;
          color: #a4a8b1;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.col-block {
  .col-block__trigger {
    height: 40px;
    cursor: pointer;
    width: -moz-available;
    padding: 8px 16px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
    display: flex;
    align-items: center;

    &:before {
      height: 24px;
      width: 24px;
      background-size: cover;
      background-image: url("../images/arrow-down.svg");
      transform: rotate(-90deg);
      content: '';
      transition: transform 0.5s ease;
    }
    &.is-disabled {
      background-color: #a4a8b1;
    }
    &.is-open:before {
      transform: rotate(0deg);
    }
  }
  .col-block__contentOuter {
    .col-block__contentInner {
      .occurrences {
        .list {
          max-height: 240px;
          overflow-y: auto;
        }
        .row {
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          margin: 16px 8px;
          padding: 8px 12px 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
          background-color: #f7f9fa;
          min-height: 40px;
        }
      }
      .logs {
        margin-top: 16px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: 206px;
        overflow-y: auto;
        .log-item {
          position: relative;
          margin-bottom: 32px;
          padding-right: 8px;
          margin-left: 50px;
          width: auto;
          display: flex;
          flex-direction: column;
          &:last-of-type {
            margin-bottom: 0;
          }
          .log-circle {
            position: absolute;
            border-radius: 50%;
            left: -20px;
            top: 1px;
            width: 13px;
            height: 13px;
            border: solid 2px #7a86a9;
            background-color: #ffffff;
          }

          .log-header {
            display: flex;
            font-family: Roboto;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #a4a8b1;
            .log-delimiter {
              margin: 0 5px;
            }
          }
          .log-comment {
            margin-top: 8px;
            display: flex;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616674;
          }
        }

      }
      .details {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        .info-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          min-height: 30px;
          margin-top: 4px;
          .info-title {
            padding: 8px 0 8px 16px;
            display: flex;
            align-items: center;
            width: 45%;
            min-width: 108px;
            margin-right: 8px;
            border-radius: 4px;
            background-color: #f7f9fa;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616674;
          }
          .info-val {
            padding: 7px 16px 7px 16px;
            width: -webkit-fill-available;
            border-radius: 4px;
            background-color: #f7f9fa;
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
          }
        }
        .switch {
          margin-top: 40px;
          margin-bottom: 32px;
        }
        
      }
      .protean-table {
        .table-header {
          padding: 4px 0;
          border-radius: 4px;
          background-color: #f7f9fa;
          font-family: Roboto;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #616674;
        }
        .table-body {
          .body-row {
            margin-top: 18px;
            padding-bottom: 18px;
            border-bottom: #eaeeef solid 1px;
            .expand-btn {
              padding: 5px;
              border-radius: 4px;
              background-color: #f7f9fa;
            }
          }
          .extra-cell {
            margin-top: 1px;
            padding: 18px 14px;
            display: flex;
            flex-direction: column;
            border-bottom: #eaeeef solid 1px;
            &.sub {
              height: auto;
              background-color: transparent;
              .cell {
                height: auto;
                margin: 8px 0;
                display: flex;
                align-items: baseline;
                span:nth-of-type(1) {
                  margin-left: 15px;
                  min-width: 100px;
                  max-width: 100px;
                }
                span:nth-of-type(2) {
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #24272a;
                }
              }
            }
            .cell {
              font-family: Roboto;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #24272a;
              display: flex;
              align-items: center;
              min-height: 32px;
              span:nth-of-type(1) {
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #616674;
                margin-left: 15px;
                width: 150px;
              }
            }
          }
        }
      }
      .group-detail-info {
        .view-details {
          font-size: 13px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          width: 100%;
        }
        padding: 9px;
        .creator, .editor, .assigned {
          .user-title {
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616674;
            margin-right: 8px;
          }
          .comment-text {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: #616674;
          }
          .highlight {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
          }
        }
      }
    }
  }
}
.sub-row {
  .view-details {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3a4669;
    margin: 8px;
    margin-top: 20px;
  }
  display: none;
  &.open {
    display: flex;
  }
  border-top: 1px #eaeeef solid;
  flex-wrap: wrap;
  &.groups {
    border-top: 1px #fff solid;
    background-color: #edf0f3;
  }
  .title {
    margin-top: 21px;
    width: 34%;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #616674;
    align-items: center;
    display: flex;
    &.issue-group {
      width:auto;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;

    }
  }
  .value {
    margin-top: 21px;
    width: 60%;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
    align-items: center;
    display: flex;
    &.issue-group-title {
      margin-top: 0;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
      margin-left: -6px;
      &.line-item {
        margin-left: 6px;
      }
    }
    &.issue-group {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      margin-left: 8px;
      &.comment {
        line-height: 1.29;
      }
    }
    .issue-photo-cell {
      width: 48px;
      height: 48px;
      margin-right: 4px;
    }
    .protean-link {
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #578ef7;
    }
    &.buttons {
      display: flex;
      .generate-btn {
        height: 32px;
        &.active {
          background-color: #86aefb;
          height: 32px;
          color: #fff;
          cursor: default;
          display: flex;
          padding: 0 16px;
          align-items: center;
          .loading-indicator {
            animation: rotation 1s linear infinite;
            margin-right: 8px;
          }
        }
      }
      .rerun {
        height: 32px;
        padding: 0 inherit;
        display: flex;
        align-items: center;
      }

      .view,
      .download {
        padding: 0;
        margin: 0 4px;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg > g > path {
          fill: #616674;
        }
      }
    }
  }
}
  .main-info {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    .info-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 30px;
      margin-top: 4px;
      .info-title {
        padding: 8px 0 8px 16px;
        display: flex;
        align-items: center;
        width: 45%;
        margin-right: 8px;
        border-radius: 4px;
        background-color: #f7f9fa;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;
      }
      .info-val {
        padding: 7px 0 7px 16px;
        width: -webkit-fill-available;
        border-radius: 4px;
        background-color: #f7f9fa;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;

        &.status {
          background-color:inherit;
          padding-left: 0px !important;
          color: #3a4669;
        }
        &.info-links {
          font-family: Roboto;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          
        }
        .wo-link {
          color: #578ef7;
        }
      }
    }
  }



  .action-button {
    height: 40px;
    cursor: pointer;
    width: -webkit-fill-available;
    margin: 8px 8px 0 8px;
    border-radius: 4px;
    background-color: #f7f9fa;
    border: none;
    padding: 13px 0;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3a4669;
    &:disabled {
      background-color: #f0f2f4;
      color: #a4a8b1;
    }
    &:focus {
      outline: none;
    }
    &.complete-button:not(:disabled) {
      background-color: #578ef7;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
  //.action:nth-of-type(2) {
  //  // hack first element
  //  margin-top: 32px;
  //}
  .divider {
    margin: 40px 0;
    height: 5px;
    width: 100%;
    background-color: #f7f9fa;
  }

  .comments-block {
    padding: 23px 16px;

    .comment {
      margin-bottom: 19px;
      &.sub {
        margin-left: 32px;
        margin-top: 19px;
        margin-bottom: 0;
      }
      .comment-user {
        font-family: Roboto;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        margin-bottom: 4px;
      }
      .comment-label {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        padding: 7px 12px;
        border-radius: 4px;
        margin-left: 8px;
        background-color: #f7f9fa;
        border-radius: 4px;
      }
      .comment-issues {
        margin: 7px 0;
        .comment-issue {
          padding: 7px 12px;
          border-radius: 4px;
          background-color: #f7f9fa;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          margin-right: 8px;
        }
      }
      .comment-text {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        margin-bottom: 8px;
        line-height: 20px;
        .mention-str {
          padding: 5px 12px;
          border-radius: 15px;
          background-color: #f7f9fa;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #3f66d4;
          white-space: nowrap;
        }
      }
      .comment-file {
        display: flex;
        flex-direction: column;
        button {
          width: fit-content;
          appearance: none;
          border: none;
          padding: 2px 4px;
          border-radius: 4px;
          background-color: #f7f9fa;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          text-align: left;
          svg {
            min-width: 18px;
            min-height: 18px;
            max-width: 18px;
            max-height: 18px;
          }
        }
      }
      .comment-date {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a4a8b1;
        .reply-btn {
          font-size: 13px;
          font-weight: 500;
          color: #a4a8b1;
          margin-left: 16px;
          &:hover {
            color: #24272a;
          }
        }
      }
      .reply-comment {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        .comment-mention {
          width: 100%;
          height: 40px;
          .comment-mention__suggestions {
            background-color: #f7f9fa !important;
            max-height: 80px !important;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 3px;
              height: 56px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 3.5px;
              background-color: #f0f2f4;
            }
            ul {
              max-height: 80px !important;
            }
            padding: 6px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
            .comment-mention__suggestions__list {
              .comment-mention__suggestions__item {
                padding: 5px 10px;
                &.comment-mention__suggestions__item--focused {
                  background-color: #eaeeef !important;
                }
              }
            }
          }
          .comment-mention {
            height: 40px;
          }
          .comment-mention__input {
            border-radius: 4px;
            border: solid 1px #eaeeef;
            background-color: #ffffff;
            padding-left: 16px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
            height: 38px;
          }
        }
        .protean-default-input {
          width: 55%;
        }
        .buttons {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .protean-btn {
            width: 45%;
            max-width: 150px;
          }
        }

      }
    }
  }
  .relatedFiles {
    display: flex;
    flex-direction: column;
    .row-block {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .protean-badge {
        width: fit-content;
        white-space: nowrap;
        margin-right: 8px;
        height: 24px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #a4a8b1;
        padding: 0 8px;
        display: flex;
        align-items: center;
      }

      .row-item {
        min-height: 40px;

        &.reports, &.GroupRelatedFiles {
          height: 36px;
        }

        display: flex;
        width: -webkit-fill-available;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background-color: #f7f9fa;
        flex-direction: column;
        .issue-list {
          width: 100%;
          flex: 1;
          flex-direction: row;
          margin-bottom: 5px;
          display: flex;
          span {
            display: flex;
            width: fit-content;
            padding: 5px 9px;
            border-radius: 15px;
            background-color: #eaeeef;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #a4a8b1;
            margin-right: 8px;
          }
        }
        .row-file {
          display: flex;
          width: -webkit-fill-available;
          justify-content: space-between;
          align-items: center;
          min-height: 40px;
          .title {
            padding: 0;
            display: flex;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
            max-width: 250px;
            word-break: break-word;
            margin: 0;
            svg {
              margin-right: 6px;
              min-width: 18px;
              min-height: 18px;
              max-width: 18px;
              max-height: 18px;
            }
          }

          .actions {
            display: flex;
            align-items: center;
            .view {
              margin-left: 16px;
              height: fit-content;
            }
            button {
              svg {
                width: 24px;
                height: 24px;
              }
            }
            .download-action {
              //margin-right: 24px;
              height: fit-content;
              background-color: unset;
              svg {
                width: 24px;
                height: 24px;
                #download {
                  fill: #616674;
                }
              }
            }
          }
        }

      }
    }
  }
  .value-row {
    display: flex;
    font-family: Roboto;
    font-size: 14px;
    line-height: normal;
    color: #24272a;
    padding: 16px 16px 16px 0;
    &.groups {
      background-color: #edf0f3;
    }
    .value {
      &.issue-group-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .expand-btn {
        width: 28px;
        height: 28px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          fill: #616674;
        }
      }
      &.priority {
        width: 25px;
        flex: 1;
        padding-left: 8px;
      }

      flex: 3;
      &.code {
        flex: 1;
      }
      &.actions {
        flex: 2;
      }

      display: flex;
      align-items: center;
      &.hide {
        flex: 1;
        place-content: end;
      }
      &.group-name {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;
        padding-left: 8px;
      }
    }
  }
  .product-header {
    height: 32px;
    /* padding: 9px 94px 9px 43px; */
    border-radius: 4px;
    background-color: #f7f9fa;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: #616674;
    display: flex;
    align-items: center;
    &.groups {
      margin-bottom: 3px;
    }
    &.issue-group-header {
      margin-left: 6px;
      margin-right: 6px;
      margin-top: 11px;

    }
    .value {
      &.actions {
        flex: 2;
      }
      &.group-name {
        padding-left: 8px;
      }

      height: fit-content;
      &.hide {
        visibility: hidden;
        flex: 1;
        place-content: end;
      }
      &.priority {
        width: 25px;
      }
      &.line-item {
        width:86px;
        flex: 1 1 86px;
      }
      flex: 3;
    }
  }

}

.custom-picker {
  z-index: 99999;
  padding: 32px;
  padding-top: 34px;
  display: none;
  position: absolute;
  top: 40px;
  left: 0;
  width: 377px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  &.show {
    display: block;
  }

  .datepicker-week-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .datepicker-months {
    display: flex;
    justify-content: space-between;
    .datepicker-block {
      width: 311px;
      .datepicker-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        margin-bottom: 25px;
        .datepicker-nav-button {
          display: flex;
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          &.hide-nav {
            visibility: hidden;
          }
        }
        .datepicker-month-title {
          align-self: center;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #24272a;
        }
      }
      .datepicker-week-day {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: center;
        color: #616674;
        width: 44.4px;
      }
      .datepicker-month-picker {
        width: 60px;
        height: 32px;
      }
      .datepicker-days-block {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .datepicker-day {
          background: none;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: center;
          color: #24272a;
          width: 44.4px;
          height: 32px;
          &.datepicker-month-picker {
            width: 77px;
            height: 50px;
            &.selected:hover {
              background-color: #bcd87a;
            }
            &:hover {
              background-color: #f7f9fa;
            }
          }
          &.selected {
            border-radius: 4px;
            background-color: #bcd87a;
            &.range {
              background-color: #bcd87a;
            }
          }
          &.range {
            background-color: #f7f9fa;
          }
          &.in-list {
            background-color: #f7f9fa;
          }
          &:disabled,
          &[disabled] {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }
  }
  &.range {
    width: 720px;
    &.single {
      width: auto;
    }
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.protean-tabs {
  &.scrollable {
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .protean-tab {
    font-size: 13px;
    font-weight: 500;
    color: #a4a8b1;
    &.active {
      color: #283049;
      &:before {
        background-color: #bcd87a
      }
    }
  }
}

.nodata {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24272a;
}

.switch {
  div {
    display: flex;
  }
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24272a;
  display: flex;
  flex-direction: row;
}

.protean-status {
  padding: 4px 12px;
  border-radius: 15px;
  background-color: #e0e8f1;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
}
.protean-toggler {
  width: 32px;
  height: 18px;
  margin: 0 0 0 8px;
  padding: 1px 1px 1px 1px;
  border-radius: 9px;
  background-color: #e0e8f1;
  display: flex;
  cursor: pointer;
  // justify-content: flex-start;
  animation: toInactive 0.3s;
  position: relative;
  &.green-double-side {
    animation: unset;
    margin-right: 8px;
    &.false, &.true {
      animation: unset;
      background-color: #bcd87a;
    }
  }
  &.true {
    background-color: #bcd87a;
    // justify-content: flex-end;
    animation: toActive 0.3s;
    .toggler {
      left: 45%;
    }
  }
  .toggler {
    position: absolute;
    transition: all 0.1s linear;
    left: 5%;
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 9px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
  }

  .logs-list {
    max-height: 235px;
    overflow-y: auto;
    position: relative;
  }
  .log-item {
    .report {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 4px;
      left: 265px;
    }
    &:nth-of-type(2) {
      margin-top: 24px;
    }
    margin-left: 57px;
  }
}

.due-date {
  position: relative;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a4a8b1;
  span {
    width: 100%;
    display: flex;
  }
  input {
    height: 38px;
    padding-left: 12px;
    font-size: 14px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #ffffff;
  }
  button {
    margin-bottom: 28px;
  }
  .field-title {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a4a8b1;
  }
}

.complete-modal {
  .datepicker-block {
    input.single-input {
      color: #24272a;
      font-weight: 400;
    }
  }
  .header{
    h2 {
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
    }
  }
  .info-note {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
  }
  .label {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a4a8b1;
  }
  .comment-textarea {
    height: 133px;
    border-radius: 4px;
    border: solid 1px #eaeeef;
    background-color: #fff;
    resize: none;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 16px;
    padding-top: 12px;
  }
  .comment-textarea::placeholder {
    font-weight: 500;
    color: #c4c7cf;

  }
  .file-name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
  }
  &.complete-modal-sent {
    .info-note {
      font-family: Roboto;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #24272a;
    }
    .protean-btn-default {
      width: 140px;
    }
  }
  &.close-modal {
    .confirmation {
      font-family: Roboto;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #24272a;
    }
    .protean-btn {
      width: 120px;
    }
  }
}

.protean-modal {
  .modal-content {
    .header {
      &.issues {
      padding-bottom: 4px;
      }
      .title {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
      }
      .text-title {
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
      }
    }
  }
}
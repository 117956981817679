.protean-modal {
  //border-radius: 4px;
  //box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  //border: solid 1px #eaeeef;
  //background-color: #fff;
  //width: 100%;
  //height: 100%;
  //position: absolute;
  //display: none;

  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(36, 39, 42, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    width: 95%;
    max-width: 500px;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeeef;
    background-color: #fff;
    background-clip: padding-box;
    transform: translateY(-50%);
    transition: transform 0.3s ease-out;
    &.view-file {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 16px;
      height: 32px;
      width: 32px;
    }
    .header {
      padding: 20px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
    }
    .body {
      padding: 12px 20px;
      display: flex;
      flex-direction: column;
      &.view-file {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        img {
          max-width:100%;
          max-height:100%;
        }
        iframe {
          height: -webkit-fill-available;
        }
      }
      .text-title {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        margin-bottom: 16px;
      }
      .muted-title {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a4a8b1;
        margin-bottom: 8px;
      }
      .comment-mention {
        height: 133px;
        textarea {
          height: 133px;
          margin: 8px 0 0;
          border-radius: 4px;
          border: solid 1px #eaeeef;
          background-color: #fff;
          resize: none;
          &:focus {
            outline: none;
            border: solid 1px #7a86a9;
          }
        }
        .comment-mention__suggestions {
          background-color: #f7f9fa !important;
          padding: 6px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;
          .comment-mention__suggestions__list {
            .comment-mention__suggestions__item {
              padding: 5px 10px;
              &.comment-mention__suggestions__item--focused {
                background-color: #eaeeef !important;
              }
            }
          }
        }
        .comment-mention__control {
          .comment-mention__input {
            border-radius: 4px;
            border: solid 1px #eaeeef;
            &:focus-visible {
              //outline: 1px solid #eaeeef !important;
              outline: none !important;
            }

          }
          outline: transparent !important;
        }
      }
      .file-list {
        margin-top: 17px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        .d-flex {
          margin-bottom: 8px;
        }
        svg {
          min-height: 18px;
          min-width: 18px;
          margin-right: 8px;
        }
      }
      .buttons {
        margin-top: 17px;
        display: flex;
        justify-content: flex-end;
        .add-file {
          height: 40px;
          min-width: 40px;
          margin-right: 12px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .upload-file {
          width: -webkit-fill-available;
          max-width: 150px;
        }
        .submit-comment {
          width: -webkit-fill-available;
          max-width: 300px;
        }
      }
    }
  }
  &.is-open {
    visibility: visible;
    opacity: 1;
    .modal-content {
      transform: translateY(0);
    }
  }
}

.mr-8 {
  margin-right: 8px !important;
}

.protean-scroll {
  &::-webkit-scrollbar {
    width: 3px;
    height: 56px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #f0f2f4;
  }
}

.ml-8 {
  margin-left: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.status-flow {
  padding: 20px;
}

.spinner-border-result {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 26px;
  height: 26px;
}

.mfa {
  .mfa-text {
    color: #24272A;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
  }

  .muted-hint {
    color: #A4A8B1;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }

  .mfa-code-error {
    display: flex;
    width: 316px;
    margin: 0 auto;
    color: #DB6969;
    font-size: 13px;
    font-weight: 400;
  }


  .mfa-text-after {
    width: 316px;
    margin: 0 auto;
  }

  .buttons {
    display: flex;
    flex-direction: column;

    button {
      width: 316px;
      margin: 0 auto;
    }
  }

  .reset-code-btn {
    color: #578EF7;
    font-size: 14px;
    font-weight: 400;
  }

  .mfa-inputs {
    display: flex;
    justify-content: center;

    input {
      width: 45px;
      height: 48px;
      display: flex;
      padding: 11px 14px;
      padding-right: 0px;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

}


.protean-badge {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeeef;
  width: fit-content;

  .badge-oval {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 8px;

    &.green {
      background-color: #bcd87a;
    }

    &.red {
      background-color: #dc6e6e;
    }

    &.yellow {
      background-color: #efc675;
    }

    &.grey {
      background-color: #a4a8b1;
    }
  }
}

.wo-badge-wrapper-oval {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeeef;
}

.wo-badge-status-oval {
  height: 10px;
  width: 10px;
  border-radius: 50%;

  &.green {
    background-color: #bcd87a;
  }

  &.red {
    background-color: #dc6e6e;
  }

  &.yellow {
    background-color: #efc675;
  }

  &.grey {
    background-color: #a4a8b1;
  }
}

.mt-16 {
  margin-top: 16px !important;
}

.Toastify {
  .Toastify__toast-container--top-center {
    z-index: 9999;
  }
}

// // SingleDatePickerFIX
// .date-custom-styles {
//     .SingleDatePickerInput__withBorder {
//         border-radius: 4px;
//         border-style: solid;
//         border-width: 1px;
//         border-color: hsl(0,0%,80%);
//     }
//     .DateInput.DateInput_1 {
//         width: 100%;
//         height: 38px;
//         border-radius: 4px;
//         .DateInput_input {
//             height: 38px !important;
//             font-size: 13px;
//             color: hsl(0,0%,50%);
//             font-weight: 400;
//             border-radius: 4px;
//         }
//     }
//     .CalendarMonth_caption.CalendarMonth_caption_1 {
//         width: 277px;
//     }
//     div {
//         width: 100%;
//     }
//     .DateInput_input__focused {
//         border: none;
//     }
//     .SingleDatePicker_picker {
//         width: auto;
//         .DayPicker {
//             width: auto;
//             .DayPickerNavigation_button {
//                 width: auto;
//             }
//         }
//     }
// }

// // custom header logo class
// .custom-header-logo {
//     padding-top: 8px;
//     padding-bottom: 8px;
//     margin-right: 20px;
//     min-width: 100px !important;
//     img {
//         height: 2rem;
//     }
// }

// .settings-button {
//     &:hover {
//         box-shadow: none !important;
//         opacity: 0.75;
//     }
//     &:active, &:focus {
//         box-shadow: none !important;
//     }

// }

.Toastify__toast-container--top-center {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Toastify__toast {
    padding: 24px;
    border-radius: 4px;
    width: 360px;
    // height: 142px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeeef;
    background-color: #ffffff;

    .Toastify__toast-body {
      font-size: 15px;
      color: #24272a;
      margin: 0;

      .content {
        padding: 0;

        .title {
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: bold;
          color: #24272a;
        }

        .text {
          font-size: 15px;
          color: #24272a;
          margin-top: 10px;
        }
      }
    }

    .Toastify__close-button {
      color: #616674;
    }
  }
}

@keyframes zoomIn {
  from {
    opacity: 1;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

// .list-group-item.list-group-item-action {
//     word-break: break-all;
// }

// .testTableWrapper {
//     border: 1px solid rgba(0, 0, 0, 0.07);
//     .card-header {
//         border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
//         .c-title {
//             color:rgb(91, 91, 96);
//             font-size: 16px;
//         }
//     }
// }

// .progress-bar-protean {
//     background-color: #bcd87a !important;
// }

// .header-link {
//     color: #333;
//     &:hover {
//         color: #333;
//         opacity: 0.75;
//     }
// }

// .facility-page {
//     font-weight: 500;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     .facility-breadcrumbs {
//         border-bottom: 5px solid #f7f9fa;
//         padding: 9px 8px 0 8px;
//         display: flex;
//         justify-content: space-between;
//         .breadcrumb {
//             display: flex;
//             flex: 0.9 1;
//             height: 31px;
//             margin: 0 4px;
//             cursor: pointer;
//             align-content: center;
//             justify-content: center;
//             border-radius: 4px;
//             background-color: #f7f9fa;
//             font-size: 12px;
//             font-weight: 700;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #616674;
//             align-items: center;
//             text-align: center;
//             &:hover {
//                 background-color: #f0f2f4;
//             }
//         }
//     }
//     .card-devider {
//         border-top: 5px solid #f7f9fa !important;
//     }
//     .facility-header {
//         align-items: end;
//         .facility-actions {
//             align-items: start;
//             .facility-action-btn {
//                 width: 48px;
//                 height: 48px;
//                 border-radius: 4px;
//                 background-color: #f7f9fa;
//                 border:none;
//                 &:hover {
//                     filter: invert(10%);
//                   }
//             }
//         }
//     }
//     .facility-title {
//         font-family: Roboto;
//         font-size: 24px;
//         color: #24272a;
//     }
//     .facility-tab-title {
//         font-family: Roboto;
//         font-size: 20px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #24272a;
//     }
//     .facility-address {
//         font-size: 19px;
//         color: #616674;
//         margin-bottom: 28px;
//     }
//     .facility-tab {
//         font-size: 13px;
//         font-weight: 500;
//         color: #a4a8b1;
//         &.active {
//             color: #283049;
//             &:before {
//             background-color: #bcd87a
//             }
//         }
//     }
//     .facility-item-header {
//         font-family: Roboto;
//         font-size: 12px;
//         font-weight: bold;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #616674;

//         min-width: 128px;
//         height: 30px;
//         align-items: center;
//         border-radius: 4px;
//         background-color: #f7f9fa;
//         padding-left: 16px;
//         margin-right: 2px;
//     }
//     .facility-item-value {
//         font-family: Roboto;
//         font-size: 14px;
//         font-weight: bold;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #24272a;

//         padding-left: 16px;
//         width: 100%;
//         border-radius: 4px;
//         background-color: #f7f9fa;
//         align-items: center;
//         margin-right: 20px;
//     }
// }

// .protean-alert {
//     width: 127px;
//     height: 40px;
//     font-family: Roboto;
//     font-size: 14px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     text-align: center;
//     padding: 12px 20px;
//     border-radius: 4px;
//     &-success {
//         color: #77972c;
//         background-color: #f1ffd0;
//     }
//     &-warning {
//         background-color: #ffdfde;
//         color: #97462c;
//     }
// }

// .protean-table {
//     width: 100%;
//     user-select: none;
//     thead {
//         tr {
//             height: 32px;
//             th {
//                 img {
//                     margin-left: 5px;
//                 }
//                 &.status {min-width: 125px;}
//                 &.permit_status {min-width: 150px;}
//                 &.priority {min-width: 100px;}
//                 &.status_priority {min-width: 100px;}
//                 &.assignees {min-width: 150px; word-break: break-word;}
//                 &.planned_date {min-width: 135px;}
//                 &.next_planned_date {min-width: 160px;}
//                 &.test_type {min-width: 125px;}
//                 &.sensor_category {min-width: 150px;}
//                 &.completion_date {min-width: 150px;}
//                 &.site_city {min-width: 130px;}
//                 &.date {min-width: 129px;}
//                 &.site_state {min-width: 90px;}
//                 &.inspector_lastname {width: 105px;}
//                 &.due_date, &.issue_date {width: 115px;}
//                 &.agency {min-width: 200px;}
//                 &.name {min-width: 130px;}
//                 padding: 0 18px;
//             }
//             border-radius: 4px;
//             background-color: #f7f9fa;  font-family: Roboto;
//             font-size: 12px;
//             font-weight: bold;
//             color: #616674;
//         }
//     }
//     &-analitycs  {
//         thead {
//             tr {
//                 height: 32px;
//                 th {
//                     img {
//                         margin-left: 5px;
//                     }
//                     &.date {width: 10%;}
//                     &.priority {width: 10%;}
//                     &.client_name {width: 10%;}
//                     &.site_address {width: 20%;}
//                     &.site_city {width: 10%;}
//                     &.site_state {width: 10%;}
//                     &.options {width: 5%;}
//                     padding: 0 18px;
//                 }
//                 border-radius: 4px;
//                 background-color: #f7f9fa;  font-family: Roboto;
//                 font-size: 12px;
//                 font-weight: bold;
//                 color: #616674;
//             }
//         }
//         tbody {
//             tr {
//                 height: 48px;
//                 &:hover {
//                     background-color: #f7f9fa;
//                 }
//                 td {
//                     font-size: 14px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #24272a;
//                     height: 48px;
//                     padding: 16px 20px 16px 20px;
//                     vertical-align: baseline;
//                 }
//             }
//         }
//     }
//     tbody {
//         tr {
//             height: 48px;
//             &:hover {
//                 background-color: #f7f9fa;
//             }
//             td {
//                 font-size: 14px;
//                 font-weight: normal;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #24272a;
//                 height: 48px;
//                 padding: 16px 20px 16px 20px;
//                 vertical-align: baseline;
//                 word-break: normal;
//                 button {
//                     word-break: keep-all;
//                 }
//                 &.inspector_lastname {
//                     word-break: normal;
//                 }
//                 &.assignees {
//                     word-break: normal;
//                 }
//                 &.inspection_date, &.date, &.created_on {
//                     word-break: normal;
//                 }
//             }
//         }
//     }
// }

// .no-records {
//     display: flex;
//     justify-content: center;
//     font-size: 20px;
//     font-weight: 300;
// }

// .protean-pagintaion {
//     display: flex;
//     align-items: center;
//     .arrow {
//         border: none;
//         background-color: transparent;
//         width: 24px;
//         height: 24px;
//         border-radius: 4px;
//         padding: 0;
//         &:hover {
//             background-color: #f0f2f4;
//         }
//         &.active {
//             background-color: #f0f2f4;
//         }
//         &:disabled {
//             &:hover {
//                 background-color: initial;
//             }
//             opacity: 0.5;
//         }
//     }
//     .pagination-button {
//         min-width: 32px;
//         height: 32px;
//         font-size: 14px;
//         color: #24272a;
//         border: none;
//         background-color: transparent;
//         &:hover {
//             background-color: #f0f2f4;
//         }
//         &.active {
//             background-color: #f0f2f4;
//         }
//     }
// }
// .pagination-size {
//     align-items: center;
//     justify-content: flex-end;
//     font-size: 14px;
//     font-weight: normal;
//     select {
//         margin-left: 8px;
//         width: 87px;
//         height: 32px;
//         border-radius: 4px;
//         border: solid 1px #eaeeef;
//         background-color: #ffffff;
//         font-size: 14px;
//         color: #24272a;
//     }
// }
// .protean-filter-block {
//     .protean-filter {
//         width: 158px;
//         height: 32px;
//         border-radius: 4px;
//         border: solid 1px #eaeeef;
//         background-color: #ffffff;
//         font-size: 13px;
//         font-weight: 500;
//         padding-left: 16px;
//         option:first {
//             font-size: 13px;
//             color: #a4a8b1;
//         }
//         &:invalid {
//             color: #a4a8b1;
//         }
//         option:not(:first-of-type) {
//             font-size: 13px;
//             color: #333;
//         }
//     }
// }

// .protean-input {
//     border-radius: 4px;
//     border: solid 1px #eaeeef;
//     background-color: #ffffff;
//     padding-left: 16px;

//     &.filter {
//         &:disabled {
//             border-radius: 4px;
//             border: solid 1px #f7f9fa;
//             background-color: #ffffff;
//             font-size: 13px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #a4a8b1;
//         }
//         &::placeholder {
//             font-size: 13px;
//             font-weight: 500;
//             color: #a4a8b1;
//         }
//         width: 200px;
//         height: 32px;
//     }
// }

// .protean-tooltip {
//     border-radius: 4px !important;
//     box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
//     background-color: #ffffff !important;
//     font-size: 13px !important;
//     font-weight: 500 !important;
//     color: #616674 !important;
//     padding: 10px !important;
//     &.show {
//         opacity: 1 !important;
//     }
//     &:after {
//         border: none !important;
//     }
// }

// .z-index-1 {
//     z-index: 1 !important;
// }

// .protean-breadcrumbs {
//     background-color: transparent;
//     padding: 0 9px;
//     .breadcrumb {
//         height: 35px;
//         align-items: center;
//     }
// }

// .protean-facility-files {
//     thead {
//         border-radius: 4px;
//         background-color: #f7f9fa;
//         tr {
//             th {
//                 border-bottom: none;
//             }
//         }
//     }
//     tbody {
//         tr {
//             td {
//                 border-top-color: #f7f9fa;
//             }
//             &:first-of-type {
//                 td {
//                     border-top: none;
//                 }
//             }
//         }
//     }
// }

// .protean-badge {
//     padding: 8px 12px;
//     font-size: 12px;
//     font-weight: bold;
//     border-radius: 4px;
//     background-color: #f7f9fa;
//     width: fit-content;
//     word-break: keep-all;
//     color: #616674;
//     &.Fail, &.Overdue {
//         color: #923838;
//         background-color: #ffcfcf;
//     }
//     &.Pass {
//         color: #516a17;
//         background-color: #f1ffd0;
//     }
//     &.Upcoming {
//         color: #766a4d;
//         background-color: #fff396;
//     }
// }

// .protean-test-badge {
//     border-radius: 12px;
//     background-color: #f7f9fa;
//     font-family: Roboto;
//     font-size: 12px;
//     font-weight: bold;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #a4a8b1;
//     padding: 5px 10px;
//     height: 24px;
//     width: fit-content;
//     .circle {
//         border-radius: 50%;
//         margin-right: 8px;
//         display: inline-block;
//         height: 10px;
//         width: 10px;
//     }
//     &.Fail, &.Overdue {
//         .circle {
//             background-color: #dc6e6e;
//         }
//     }
//     &.Pass {
//         .circle {
//             background-color: #bfe85f;
//         }
//     }
//     &.Upcoming {
//         .circle {
//             background-color: #efc675;
//         }
//     }
//     &.OverduePending {
//         .circle {
//             background-color: #dc6e6e;
//         }
//     }
//     &.Pending {
//         .circle {
//             background-color: #efc675;
//         }
//     }
// }

// .w-10 {
//     width: 10%;
// }
// .w-15 {
//     width: 15%;
// }

.mr-8 {
  margin-right: 8px;
}

// .ml-8 {
//     margin-left: 8px;
// }
// .mt-8 {
//     margin-top: 8px;
// }

// .mx-7px {
//     margin-left: 7px;
//     margin-right: 7px;
// }

// .protean-svg {
//     display: inline;
//     margin-right: 12px;
//     g {
//         fill: #3a4669;
//     }
// }

// .protean-navbar {
//     height: 72px;
//     background-color: #283049;
//     .button-title {
//         font-size: 16px;
//         font-weight: normal;
//         color: #ffffff;
//     }
//     .protean-notification-icon {
//         font-size: 20px;
//     }
//     .protean-pill {
//         top: -6px;
//         left: -5px;
//         width: 10px;
//         height: 10px;
//         background-color: #c3e27a;
//         border-color: #283049;
//     }
//     .protean-logo-block {
//         display: flex;
//         align-items: center;
//     }
// }
// .page-header.page-header-light {
//     .breadcrumb-line {
//         height: 60px;
//     }
// }

.protean-page-title {
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #616674;
}

// .protean-default-input {
//     font-size: 14px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #24272a;
//     width: 100%;
//     &::placeholder {
//         font-family: Roboto;
//         font-size: 13px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #a4a8b1;
//     }
//     &::-moz-placeholder {
//         line-height: revert;
//     }
//     border-radius: 4px;
//     border: solid 1px #eaeeef;
//     background-color: #ffffff;
//     height: 38px;
//     padding: 16px 12px;
//     &.error {
//         border: solid 1px #f6a4a4;
//     }
//     &:focus {
//         border: solid 1px #7a86a9;
//         outline: none;
//     }
//     &:hover {
//         border-color: #7a86a9;
//     }
//     &:disabled {
//         font-size: 13px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #a4a8b1;
//         border: solid 1px #f7f9fa;
//         width: 100%;
//     }
// }

// .protean-registration {
//     width: 100%;
//     height: 100vh;
//     background-color: #283049;
//     &.reset {
//         .content {
//             .card {
//                 .reg-title {
//                     margin-bottom: 47px;
//                 }
//                 .card-body {
//                     min-height: 362px;
//                     padding-top: 70px;
//                     button {
//                         margin-top: 16px;
//                     }
//                     .message {
//                         margin-top: 80px;
//                         text-align: center;
//                         font-size: 19px;
//                         font-weight: 500;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #616674;
//                     }
//                 }
//             }
//         }
//     }
//     &.login {
//         .content {
//             .card {
//                 .reg-title {
//                     margin-bottom: 47px;
//                 }
//                 .card-body {
//                     min-height: 362px;
//                     padding-top: 70px;
//                     button {
//                         margin-top: 16px;
//                     }
//                     .login-footer {
//                         text-align: center;
//                         font-size: 14px;
//                         font-weight: normal;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #3f66d4;
//                     }
//                 }
//             }
//         }
//     }
//     .content {
//         width: 100%;
//         height: 100vh;
//         img {
//             height: 49px;
//             margin-top: 74px;
//             margin-bottom: 104px;
//         }
//         .card {
//             width: 579px;
//             border-radius: 4px;
//             box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
//             border: solid 1px #eaeeef;
//             background-color: #ffffff;
//             .card-body {
//                 min-height: 551px;
//                 &.token-error {
//                     padding: 135px 0;
//                     height: auto;
//                     font-size: 19px;
//                     font-weight: 500;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: 1.37;
//                     letter-spacing: normal;
//                     text-align: center;
//                     color: #616674;
//                 }
//                 padding: 54px 66px 40px 66px;
//                 input {
//                     margin-bottom: 8px;
//                 }
//                 .reg-title {
//                     font-family: Roboto;
//                     font-size: 24px;
//                     font-weight: 500;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #24272a;
//                 }
//                 .reg-sub-title {
//                     font-size: 19px;
//                     font-weight: 500;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #616674;
//                     margin-bottom: 32px;
//                 }
//                 .protean-default-input[type="password"].error {
//                     margin-bottom: 0;
//                 }
//                 span.error {
//                     font-size: 13px;
//                     font-weight: normal;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #db6969;
//                     display: block;
//                     margin-bottom: 8px;
//                 }
//                 button {
//                     margin-bottom: 32px;
//                 }

//                 .agree-terms {
//                     display: flex;
//                     align-items: baseline;
//                     span {
//                         font-size: 14px;
//                         font-weight: 500;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #a4a8b1;
//                         margin: 0 2px 0 5px;
//                         cursor: pointer;
//                     }
//                     a {
//                         font-size: 14px;
//                         font-weight: normal;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         text-align: right;
//                         color: #3f66d4;
//                     }
//                 }

//                 .reg-singin {
//                     font-family: Roboto;
//                     font-size: 14px;
//                     font-weight: 500;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #a4a8b1;
//                     a {
//                         font-family: Roboto;
//                         font-size: 14px;
//                         font-weight: normal;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         text-align: right;
//                         color: #3f66d4;
//                     }
//                 }
//             }
//         }
//     }
// }

// .svg-fill-icon {
//     margin-bottom: 2px;
//     fill: #616674;
//     path {
//         fill: #616674;
//     }
//     g {
//         fill: #616674;
//     }
//     &.image {
//         fill: none;
//         g {
//             fill: none;
//             path {
//                 &:nth-child(2) {
//                     fill: #616674;
//                 }
//                 fill: none;
//             }
//         }
//     }
// }

// .protean-select {
//     position: relative;
//     img {
//       position: absolute;
//       right: 16px;
//       top: 10px;
//     }
//     .selected-text {
//         margin: 0 8px;
//         width: inherit;
//         padding: 8px 12px;
//         appearance: none;
//         border-radius: 4px;
//         border: solid 1px #eaeeef;
//         background-color: #ffffff;
//         span {
//             font-family: Roboto;
//             font-size: 13px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #a4a8b1;
//         }
//     }
//     .select-options {
//         position: absolute;
//         width: calc(100% - 16px);
//         left: 8px;
//         top: 0;
//         border-radius: 4px;
//         box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
//         border: solid 1px #eaeeef;
//         background-color: #ffffff;
//         margin: 0;
//         padding: 0;
//         z-index: 9;
//         li {
//             &:first-of-type {
//                 margin-top: 0;
//                 border-top-left-radius: 4px;
//                 border-top-right-radius: 4px;
//             }
//             &:last-of-type {
//                 margin-bottom: 0;
//                 border-top-left-radius: 4px;
//                 border-top-right-radius: 4px;
//             }
//             &.active {
//                 background-color: #f7f9fa;
//             }
//             font-family: Roboto;
//             font-size: 14px;
//             font-weight: normal;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #24272a;

//             margin: 4px 0;
//             list-style-type: none;
//             padding: 8px 12px;
//             cursor: pointer;
//         }

//         li:hover {
//             background-color: #f7f9fa;
//         }
//     }
// }

// .protean-files-page {
//     .card-body {
//         padding: 8px 33px !important;
//         .protean-cabinet-files {
//             .protean-file-table {
//                 font-size: 14px !important;
//                 font-weight: normal !important;
//                 color: #24272a !important;
//                 thead {
//                     &:first-child {
//                         tr {
//                             background-color: #f7f9fa;
//                             th:first-child {
//                                 text-align: center;
//                                 border-top-left-radius: 4px;
//                                 border-bottom-left-radius: 4px;
//                             }
//                             th:last-child {
//                                 border-top-right-radius: 4px;
//                                 border-bottom-right-radius: 4px;
//                             }
//                         }
//                     }
//                     font-size: 12px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #616674;
//                     tr {
//                         // padding: 8px 20px !important;
//                         font-size: 12px;
//                         font-weight: bold;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #616674;
//                         th {
//                             padding: 9px 20px;
//                             height: 32px;
//                             border-bottom: none;
//                             &.name {width: auto;}
//                             &.id {width: 4%;}
//                             &.last_modified {width: 10%;}
//                             &.created_on {width: 10%;}
//                             &.Options {width: 5%;}
//                         }
//                         th.sorting:after {
//                             content: url('../images/sorting-table.svg');
//                             margin-top: 0;
//                             top: 30%;
//                             right: auto;
//                             margin-left: 8px;
//                         }
//                         th.sorting:nth-of-type(2) {
//                             width: 70%;
//                         }
//                         th.sorting:nth-of-type(3) {
//                             min-width: 135px;
//                         }
//                         th.sorting:before, .sorting_asc:before, .sorting_desc:before {
//                             content: '';
//                             display: none;
//                         }
//                         th.sorting_asc:after {
//                             content: url('../images/sorting-table-up.svg');
//                             margin-top: 0;
//                             top: 25%;
//                             right: auto;
//                             margin-left: 8px !important;
//                         }
//                         th.sorting_desc:after {
//                             content: url('../images/sorting-table-down.svg');
//                             margin-top: 0;
//                             margin-left: 8px;
//                             top: 39%;
//                             right: auto;
//                         }
//                     }
//                 }
//                 tbody {
//                     &.isDragActive {
//                         border-radius: 4px;
//                         border: solid 1px #3f66d4;
//                         background-color: #f7f9fa !important;
//                     }
//                     tr {

//                         &.selected, &:hover {
//                             background-color: #f7f9fa;
//                         }
//                         td {
//                             &:first-of-type {
//                                 vertical-align: middle;
//                             }
//                             border-top: none;
//                             vertical-align: top;
//                             .sub-breadcrumbs {
//                                 padding-left: 7px;
//                                 .protean-btn {
//                                     font-size: 12px;
//                                     font-weight: 500;
//                                     font-stretch: normal;
//                                     font-style: normal;
//                                     line-height: normal;
//                                     letter-spacing: normal;
//                                     color: #a4a8b1;

//                                     border: none;
//                                     background-color: transparent;
//                                     margin-right: 4px;
//                                     padding: 0;
//                                     height: 39px;
//                                     i {
//                                         font-size: 12px;
//                                     }
//                                     &:hover {
//                                         color: #616674;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .fancytree-node {
//                         cursor: default;
//                         .fancytree-title.file {
//                             cursor: default;
//                             color: #24272a !important;
//                         }
//                         .fancytree-icon {
//                             margin-top: 0;
//                             color: #24272a !important;
//                         }
//                     }
//                     .fancytree-ico-ef {
//                         .fancytree-icon {
//                             margin-top: 0;
//                             td:nth-of-type(2) {
//                                 .fancytree-title {
//                                     font-size: 14px;
//                                     font-weight: normal;
//                                     color: #24272a !important;
//                                 }
//                             }
//                         }
//                         td:nth-of-type(2) {
//                             .fancytree-title {
//                                 font-size: 14px;
//                                 font-weight: normal;
//                                 color: #24272a !important;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     .card-header {
//         padding: 8px 33px;
//         .header-elements-inline {
//             .card-title {
//                 font-family: Roboto;
//                 font-size: 24px;
//                 font-weight: 500;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #24272a;
//             }
//         // padding-top: 22px;
//         // padding-bottom: 8px;
//         }
//     }
//     .card-header.cabinet-actions {
//         padding: 8px 33px;
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         height: 60px;
//         .protean-btn {
//             line-height: normal;
//         }
//         .tooltip-block {
//             display: flex;
//             margin-left: auto;
//             .ecabinet-tooltip {
//                 width: 200px;
//                 padding: 14px;
//                 font-family: Roboto;
//                 font-size: 13px;
//                 font-weight: normal;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: 1.54;
//                 letter-spacing: normal;
//                 color: #616674;
//             }
//         }

//     }
//     .protean-breadcrumbs-main {
//         padding-top: 8px !important;
//         padding-bottom: 8px !important;
//         .protean-e-cabinet-breadcrumbs {
//             height: auto;
//             .breadcrumb {
//                 div {
//                     display: flex;
//                     margin: 4px 0;
//                     .protean-btn {
//                         padding: 8px;
//                         line-height: normal;
//                         height: 31px !important;
//                     }
//                 }
//             }
//         }
//     }

// }

// .protean-checkbox {
//     &:before {
//         display: block;
//         width: 14px;
//         height: 14px;
//         content: "";
//         background: #FFF;
//         background-image: url('../images/checkboxOff.svg');
//     }
//     &:checked:before {
//         background-image: url('../images/checkboxOn.svg');
//         background-size: 14px;
//         background-position: center;
//         background-repeat: no-repeat;
//     }
// }

// .protean-modal {
//     box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
//     border: solid 1px #eaeeef;
//     background-color: #ffffff;
//     // width: 511px;
//     // height: 238px;
//     .card-title {
//         font-size: 16px;
//         font-weight: bold;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 1.25;
//         letter-spacing: normal;
//         text-align: center;
//         color: #24272a;
//     }
//     .card-body {
//         .label {
//             font-size: 13px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #a4a8b1;
//             margin-bottom: 16px
//         }
//     }
// }

// .base-modal {
//     &.card {
//         padding: 0;
//         .card-header {
//             padding: 32px;
//             .card-title {
//                 font-size: 20px;
//                 font-weight: 500;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #24272a;
//             }
//         }
//         .card-body  {
//             padding: 32px;
//             padding-top: 0;
//             &.events {
//                 padding-right: 16px;
//                 .events-wrapper {
//                     overflow: auto;
//                     max-height: 500px;
//                     padding-right: 14px;
//                     &::-webkit-scrollbar {
//                         width: 3px;
//                         height: 56px;
//                     }
//                     &::-webkit-scrollbar-thumb {
//                         border-radius: 3.5px;
//                         background-color: #f0f2f4;
//                     }
//                     .event {
//                         margin-bottom: 8px;
//                         padding: 9px 7px;
//                         border-radius: 4px;
//                         background-color: #f7f9fa;
//                         font-size: 11px;
//                         font-weight: bold;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #616674;
//                         .event-icon {
//                             display: inline-flex;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// .no-rows {
//     &.isDragActive {
//         border-radius: 4px;
//         border: solid 1px #3f66d4;
//         background-color: #f7f9fa !important;
//     }
//     height: 100px;
//     line-height: 100px;
//     color: #616674;
//     font-size: 13px;
//     text-align: center;
//     font-weight: 500;
//     font-stretch: normal;
//     font-style: normal;
//     letter-spacing: normal;
// }

// .fancytree-drop-target {
//     opacity: 1;
//     &.fancytree-drop-accept {
//         // opacity: 1;
//         td {
//             background-color: #f7f9fa !important;
//             .fancytree-node {
//                     background-color: transparent !important;
//             }
//         }
//     }
// }

// // .fancytree-drop-target.fancytree-drop-accept a {
// //     background-color: #2196F3 !important;
// //     color: #fff !important;
// //     text-decoration: none;
// // }

// .fancytree-drag-source {
//     opacity: 0;
//     .fancytree-drag-accept {
//         opacity: 1;
//     }
//     .fancytree-drag-reject {
//         opacity: 1;
//     }
// }

// .protean-analytics {
//     &.card {
//         padding: 0;
//         .card-header {
//             padding: 32px 32px 32px 32px;
//             margin: 0;
//             .title {
//                 font-size: 24px;
//                 font-weight: 500;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #24272a;
//                 margin-bottom: 12px;
//             }
//             .description {
//                 font-size: 14px;
//                 font-weight: normal;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #616674;
//             }
//         }
//         .card-body {
//             padding: 16px;
//             height: fit-content;
//             position: relative;
//             .fetch-filters {
//                 &.false {
//                     .Collapsible{
//                         .Collapsible__contentOuter {
//                             overflow: hidden;
//                         }
//                     }
//                 }
//                 &.true {
//                     .Collapsible{
//                         .Collapsible__contentOuter {
//                             overflow: visible !important;
//                         }
//                     }
//                 }
//                 .filter-date {
//                     & > span {
//                         display: block;
//                         margin-bottom: 8px;
//                         font-size: 13px;
//                         font-weight: 500;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #a4a8b1;
//                     }
//                     .protean-select{
//                         .select-options {
//                             left: 0;
//                             width: calc(100% - 8px);
//                         }
//                         .selected-text {
//                             margin-left: 0;
//                         }
//                     }
//                 }
//                 .fetch-filter {
//                     span:first-of-type {
//                         display: block;
//                         margin-bottom: 8px;
//                         font-size: 13px;
//                         font-weight: 500;
//                         font-stretch: normal;
//                         font-style: normal;
//                         line-height: normal;
//                         letter-spacing: normal;
//                         color: #a4a8b1;
//                     }
//                 }
//             }
//         }
//     }
// }

// .facility-tabs {
//     .facility-tab {
//         font-size: 13px;
//         font-weight: 500;
//         color: #a4a8b1;
//         &.active {
//             color: #283049;
//             &:before {
//             background-color: #bcd87a
//             }
//         }
//     }
// }

// .report-list {
//     border-radius: 4px;
//     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
//     border: solid 1px #eaeeef;
//     margin-bottom: 44px;
// }
// button:focus, input:focus {
//     outline: none;
// }
// .card.protean-report {
//     border-top-left-radius: 4px;
//     border-top-right-radius: 4px;
//     border-bottom-left-radius: 0px;
//     border-bottom-right-radius: 0px;
//     border: none;
//     box-shadow: none;
//     padding: 32px;
//     margin-bottom: 0;
//     span.report-title {
//         font-size: 24px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #24272a;
//         margin-bottom: 30px;
//         cursor: pointer;
//         svg {
//             margin-bottom: 3px;
//         }
//     }
//     &:last-of-type {
//         border-top-left-radius: 0px;
//         border-top-right-radius: 0px;
//         border-bottom-left-radius: 4px;
//         border-bottom-right-radius: 4px;
//         margin-top: 5px;
//     }
//     .card-header {
//         position: relative;
//         padding: 0;
//         margin-bottom: 30px;
//         .report-title {
//             font-size: 24px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #24272a;
//         }
//         .report-description {
//             font-size: 14px;
//             font-weight: normal;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #616674;
//         }
//     }
//     .card-body {
//         padding: 0;
//         .col-6 {
//             .keyword-hints {
//                 border-radius: 4px;
//                 background-color: #f7f9fa;
//                 padding: 17px;

//                 font-size: 12px;
//                 font-weight: bold;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #616674;
//                 ul {
//                     padding-left: 11px;
//                     .hint-row {
//                         ul > li {
//                             margin-top: 12px;
//                         }
//                         margin-bottom: 19px;
//                         code {
//                             border-radius: 4px;
//                             background-color: #ffffff;
//                             padding-left: 14px;
//                             padding-right: 14px;

//                             font-size: 12px;
//                             font-family: 'PT Mono', monospace;
//                             font-weight: bold;
//                             font-stretch: normal;
//                             font-style: normal;
//                             line-height: normal;
//                             letter-spacing: normal;
//                             color: #616674;
//                         }
//                     }
//                 }
//             }
//             .report-input {
//                 margin-bottom: 24px;
//                 &:last-of-type {
//                     margin-bottom: 24px;
//                 }
//             }
//         }
//         .report-input {
//             &:last-of-type {
//                 margin-bottom: 60px;
//             }
//             margin-bottom: 24px;

//             .report-input-title {
//                 font-size: 13px;
//                 font-weight: 500;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #a4a8b1;
//             }
//             .report-input-hint {
//                 font-size: 13px;
//                 font-weight: normal;
//                 font-stretch: normal;
//                 font-style: italic;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #a4a8b1;
//             }
//             .sheet-types-check {

//                 font-size: 14px;
//                 font-weight: normal;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #24272a;

//                 &:first-of-type {
//                     margin-top: 12px
//                 }
//                 .sheet-input {
//                     border-radius: 4px;
//                     border: solid 1px #eaeeef;
//                     &:hover {
//                         border: solid 1px #7a86a9;
//                     }
//                     &:focus {
//                         outline: none;
//                         border-color: transparent;
//                         box-shadow: 0 0 0 1px #666;
//                     }
//                 }
//                 .report-input-checkbox {
//                     &:before {
//                         display: block;
//                         width: 14px;
//                         height: 14px;
//                         content: "";
//                         background: #FFF;
//                         background-image: url('../images/checkboxOff.svg');
//                     }
//                     &:checked:before {
//                         background-image: url('../images/checkboxOn.svg');
//                         background-size: 14px;
//                         background-position: center;
//                         background-repeat: no-repeat;
//                     }
//                 }
//                 align-items: center;
//                 margin: 6px 0;
//                 div {
//                     align-items: center;
//                     padding:  6px 10px;
//                     &.type-title {
//                         border-radius: 4px;
//                         background-color: #f7f9fa;
//                         padding:  6px 10px;
//                     }
//                     span {
//                         margin-left: 8px;
//                     }
//                 }
//             }
//             .double-select {
//                 width: 100%;
//                 .double-select-item {
//                     width: 48%;
//                     &:first-of-type {
//                         margin-right: 4%;
//                     }
//                 }
//             }
//         }
//         &.report-doc-section {
//             margin-top: 24px;
//             .faq-sub-title {
//                 font-size: 19px;
//                 font-weight: 500;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 color: #616674;
//             }
//         }
//     }
// }

// .share-modal-subtitle {
//     font-size: 14px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #616674;
//     margin-bottom: 16px;
// }

// .share-modal-content {
//     font-size: 14px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #616674;
//     justify-content: space-between;
//     .share-user {
//         width: 48%
//     }
//     .share-till {
//         width: 28%;
//     }
//     .share-button {
//         width: 19%;
//     }
// }

// .analytics-message {
//     .message-title {
//         font-size: 16px;
//         font-weight: bold;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 1.25;
//         letter-spacing: normal;
//         color: #24272a;
//         padding-left: 9px;
//     }
//     .message-text {
//         font-size: 15px;
//         font-weight: normal;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #24272a;
//         padding: 0 9px;
//         &:last-of-type {
//             margin-bottom: 20px;
//         }
//         &:first-of-type {
//             margin-top: 15px;
//         }
//     }
// }

// .card.card-tems {
//     padding: 32px;
//     .card-header {
//         position: relative;
//         padding: 0;
//         margin: 14px 0;
//         font-size: 24px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #24272a;
//         .last-updated {
//             position: absolute;
//             bottom: -32px;
//             right: 0;
//             font-size: 14px;
//             font-weight: normal;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: 1.57;
//             letter-spacing: normal;
//             color: #616674;
//         }
//     }
//     .card-body {
//         margin-top: 32px;
//         .item-title {
//             font-size: 19px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #616674;
//             margin: 32px 0;
//         }
//         .item-sub-title {
//             font-size: 14px;
//             font-weight: bold;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: 1.57;
//             letter-spacing: normal;
//             text-align: justify;
//             color: #616674;
//             margin-bottom: 4px;
//         }
//         .terms-text {
//             font-size: 14px;
//             font-weight: normal;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: 1.57;
//             letter-spacing: normal;
//             text-align: justify;
//             color: #616674;
//         }
//         .terms-inner-text {
//             font-size: 14px;
//             font-weight: normal;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: 1.57;
//             letter-spacing: normal;
//             text-align: justify;
//             color: #616674;
//             margin-left: 24px;
//         }
//         .terms-link {
//             color: #616673;
//             &:hover {
//                 color: #1d1d1d;
//                 text-decoration: underline;
//             }
//         }
//     }
// }

// .fuel-system {
//     .fuel-item{
//         display: flex;
//         flex-direction: row;
//         padding: 0 24px 14px 24px;
//         .vertical-tabs {
//             margin-right: 24px;
//             display: flex;
//             flex-direction: column;
//             width: 144px;
//             .triangle {
//                 width: 0;
//                 height: 0;
//                 border-top: 16px solid transparent;
//                 border-left: 14px solid #e0e8f1;
//                 border-bottom: 16px solid transparent;
//             }
//             .vertical-tab {
//                 position: relative;
//                 width: 130px;
//                 height: 32px;
//                 margin-bottom: 8px;
//                 border-radius: 2px;
//                 background-color: #e0e8f1;
//                 font-size: 13px;
//                 font-weight: bold;
//                 font-stretch: normal;
//                 font-style: normal;
//                 line-height: normal;
//                 letter-spacing: normal;
//                 text-align: center;
//                 color: #a4a8b1;
//                 border-color: transparent;
//                 &-main {
//                     color: #616674;
//                     border-radius: 2px !important;
//                     &.active {
//                         color: #616674 !important;
//                     }
//                     width: 161px;
//                     .tab-icon {
//                         position: absolute;
//                         right: 8px;
//                         top: 7px;
//                     }
//                 }
//                 &.active {
//                     border-top-right-radius: 0;
//                     border-bottom-right-radius: 0;
//                     color: #3a4669;
//                 }
//             }
//         }
//         .fuel-tab-content {
//             display: flex;
//             width: 100%;
//             flex-direction: column;
//             .fuel-details{
//                 margin-bottom: 10px;
//                 width: 100%;
//                 display: flex;
//                 justify-content: space-between;
//                 .fuel-content {
//                     width: 49%;
//                 }
//                 .fuel-content-devider {
//                     width: 100%;
//                     height: 10px;
//                 }
//                 .fuel-system-content-item {
//                     .facility-item-header {
//                         min-width: 206px;
//                     }
//                 }
//             }
//         }
//     }
// }

// .protean-dashboard {
//     &.card {
//         border-radius: 4px;
//         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
//         border: solid 1px #eaeeef;
//         background-color: #ffffff;
//         .protean-devider {
//             border-bottom: solid 5px #f7f9fa;
//         }
//     }
//     .calendar-widget {
//         padding: 32px 29px 34px 29px;
//         .calendar {
//             padding: 35px 11px 0 11px;
//             .calendar-info {
//                 align-items: center;
//                 display: flex;
//                 .week {
//                     border-radius: 15px;
//                     background-color: #f7f9fa;
//                     font-size: 12px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #a4a8b1;
//                     padding: 5px 9px;
//                     margin-left: 15px;
//                     margin-right: 9px;
//                 }
//             }
//             .calendar-header {
//                 height: 27px;
//                 display: flex;
//                 flex-direction: row;
//                 flex-wrap: nowrap;
//                 .calendar-col {
//                     flex: 2;
//                     font-size: 13px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #c4c7cf;
//                     .current {
//                         border-bottom: solid 2px #578ef7;
//                         padding-right: 5px;
//                         padding-bottom: 5px;
//                     }
//                 }
//             }
//             .calendar-body {
//                 border: solid 2px #eaeeef;
//                 border-bottom: none;
//                 display: flex;
//                 flex-direction: row;
//                 flex-wrap: nowrap;
//                 .calendar-col {
//                     max-height: 580px;
//                     overflow: hidden;
//                     padding: 7.5px 3.5px 7.5px 3.5px;
//                     flex: 2;
//                     font-size: 13px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #c4c7cf;
//                     border-left: solid 2px #eaeeef;
//                     border-right: solid 2px #eaeeef;
//                     .facility-row {
//                         margin-bottom: 12px;
//                         .calendar-item {
//                             height: 30px;
//                             font-family: Roboto;
//                             font-size: 11px;
//                             font-weight: bold;
//                             font-stretch: normal;
//                             font-style: normal;
//                             line-height: normal;
//                             letter-spacing: normal;
//                             color: #616674;
//                             border-radius: 4px;
//                             background-color: #f7f9fa;
//                             display: none;
//                             align-items: center;
//                             margin-bottom: 4px;
//                             span {
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }
//                             min-width: 0;
//                             .event-icon {
//                                 margin: 0 3px 0 7px;
//                                 display: inline-flex;
//                             }
//                         }
//                     }
//                 }
//             }
//             .calendar-footer {
//                 border: solid 2px #eaeeef;
//                 border-top: none;
//                 display: flex;
//                 flex-direction: row;
//                 flex-wrap: nowrap;
//                 .calendar-col {
//                     flex: 2;
//                     padding: 7.5px 3.5px 7.5px 3.5px;
//                     font-size: 13px;
//                     font-weight: bold;
//                     font-stretch: normal;
//                     font-style: normal;
//                     line-height: normal;
//                     letter-spacing: normal;
//                     color: #c4c7cf;
//                     border-left: solid 2px #eaeeef;
//                     border-right: solid 2px #eaeeef;
//                     .calendar-item {
//                         display: flex;
//                     }
//                 }
//             }
//         }
//     }
//     .dashboard-title {
//         font-family: Roboto;
//         font-size: 20px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #24272a;
//         &-24 {
//             font-size: 24px;
//         }
//     }
// }

// .analitycs-tooltip {
//     width: 462px;
//     // height: 424px;
//     padding: 14px;
//     font-family: Roboto;
//     font-size: 13px;
//     font-weight: normal;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.54;
//     letter-spacing: normal;
//     color: #616674;
//     .protean-btn.icon {
//         &:first-of-type {
//             padding-top: 4px;
//         }
//         padding: 6px;
//     }
//     ul {
//         padding: 0;
//         margin: 0;
//         & > li {
//             font-size: 20px;
//             margin-left: 19px;
//             margin-bottom: 5px;
//             line-height: 10px;
//             span {
//                 font-size: 13px;
//             }
//         }
//     }
//     .title {
//         font-size: 13px;
//         font-weight: 900;
//         color: #616674;
//     }
// }

// .protean-loader {
//     color: #7a86a9 !important;
// }

// .protean-footer {
//     margin-top: 110px;
//     border-top: #f2f4f6 5px solid;
//     height: 93px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 24px;
//     .rights-block {
//         display: flex;
//         align-items: center;
//         font-family: Roboto;
//         font-size: 13px;
//         font-weight: 500;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: normal;
//         letter-spacing: normal;
//         color: #616674;
//         svg {
//             margin-right: 16px;
//         }
//     }
//     .terms-block {
//         a {
//             font-family: Roboto;
//             font-size: 13px;
//             font-weight: 500;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: normal;
//             letter-spacing: normal;
//             color: #616674;
//         }
//     }
// }

// .trends-report {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 41px;
//     border-radius: 2px;
//     background-color: #f7f9fa;

//     min-width: 200px !important;
//     font-size: 14px;
//     font-weight: 500;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #616674;
//     border: solid 1px #eaeeef !important;
//     .report-icon {
//         g {
//             fill: #616674;
//         }
//     }
//     &:hover {
//         .report-icon {
//             g {
//                 fill: #333;
//             }
//         }
//     }
// }

// .nav-item {
//     .nav-link.main-icon {
//         display: flex !important;
//         align-items: center;
//         padding-top: 14px !important;
//         padding-bottom: 14px !important;
//         .sidebar-toggle-icon {
//             display: flex;
//         }
//     }
//     .sidebar-devider {
//         // min-width: 28px;
//         margin: 0 25px;
//         border-bottom: solid 1px #eaeeef;
//         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
//     }

// }

// .hide {
//     visibility: hidden;
// }

// .image-loader {
//     height: 116px;
//     width: 154px;
//     & > div:first-of-type {
//         min-height: 116px;
//     }
// }

// .image-page {
//     width: 100%;
//     img {
//         width: auto;
//     }
// }

// .list-icons {
//     margin-left: 25px;
//     border-radius: 4px;
//     background-color: #f0f2f4;
//     .items-wrapper {
//         height: inherit;
//         .dropdown-menu.dropdown-menu-left {
//             border-color: transparent;
//             border-radius: 4px;
//             background-color: #f0f2f4;
//             .dropdown-item {
//                 &:hover {
//                     background-color: #eaeeef !important;
//                 }
//                 svg {
//                     margin-right: 8px;
//                 }
//             }

//         }
//         .btn.dropdown-toggle {
//             height: inherit;
//         }
//     }
// }

// .z3 {
//     z-index: 3;
// }

// .link_as_text {
//     font-style: normal;
//     font-variant: normal;
//     text-rendering: auto;
//     line-height: 1;
//     color: #333;
//     &:hover {
//         color: #333;
//     }
// }

@media (min-width: 768px) {
  .sidebar:not(.sidebar-component) {
    position: static !important;
  }
}

.sidebar:not(.sidebar-component) {
  // fix for side bar
  position: fixed;
}

.main-icon {
  align-items: center;
  padding-top: 14px !important;
  padding-bottom: 14px !important;

  .sidebar-toggle-icon {
    display: flex;
  }
}

.sidebar-mobile-main {
  .main-icon {
    display: none !important;
  }
}

.reset-button {
  -webkit-appearance: unset;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.protean-navbar-logo {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  img {
    height: 26px;
  }
}

.login-btn {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.protean-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f66d4;
}

.protean {
  &-auth {
    width: 100%;
    min-height: 100vh;
    background-color: #283049;

    .auth-logo {
      padding-top: 40px;
      padding-bottom: 26px;
      display: flex;
      justify-content: center;

      img {
        height: 49px;
      }
    }

    .auth-content {
      width: 100%;
      display: flex;
      justify-content: center;

      .auth-card {
        max-width: 456px;
        margin: 0 16px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #eaeeef;
        background-color: #ffffff;

        .auth-header {
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 24px;

          .title {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
          }

          .sub-title {
            justify-content: center;
            display: flex;
            font-family: Roboto;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            text-align: center;
            color: #616674;
          }
        }

        .auth-body {
          padding: 0 16px;
          padding-bottom: 22px;
          min-width: 300px;

          &.token-error {
            padding: 135px 0;
            height: auto;
            font-size: 19px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            text-align: center;
            color: #616674;
          }

          span.error {
            font-family: Roboto;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #db6969;
          }

          .protean-link {
            margin: 16px 0;
          }

          .login-invitation {
            margin-top: 18px;

            span {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #a4a8b1;
            }
          }

          .reg-singin {
            margin-top: 17px;

            span {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #a4a8b1;
            }

            a {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: #3f66d4;
            }
          }

          .agree-terms {
            margin: 16px 0;
            display: flex;
            align-items: baseline;

            span {
              margin-left: 5px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #a4a8b1;
              cursor: pointer;
            }

            a {
              margin-left: 3px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: right;
              color: #3f66d4;
            }
          }
        }
      }
    }
  }
}

.protean-input {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24272a;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  height: 38px;
  padding: 16px 12px;
  margin: 4px 0;

  &::placeholder {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a4a8b1;
  }

  &::-moz-placeholder {
    line-height: revert;
  }

  &.error {
    border: solid 1px #f6a4a4;
  }

  &:focus {
    border: solid 1px #7a86a9;
    outline: none;
  }

  &:hover {
    border-color: #7a86a9;
  }

  &:disabled {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a4a8b1;
    border: solid 1px #f7f9fa;
    width: 100%;
  }
}

.protean-checkbox {
  -webkit-appearance: unset !important;
  height: fit-content;

  &.wh-20 {

    &:before,
    &:checked:before {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }

  &:before {
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    // background-color: #FFF;
    background-image: url('../images/checkboxOff.svg');
  }

  &:checked:before {
    background-image: url('../images/checkboxOn.svg');
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.protean-btn {
  border: none;
  border-radius: 4px;
  background-color: #f7f9fa;
  padding: 9px 20px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: unset;
  letter-spacing: normal;
  text-align: center;
  color: #3a4669;
  height: 39px;

  &:focus {
    outline: none !important;
  }

  .ladda-spinner {
    display: none;
  }

  &.hw32 {
    height: 32px;
    width: 32px;
  }

  &.hw40 {
    height: 40px;
    width: 40px;
  }

  &.hw48 {
    height: 48px;
    width: 48px;
    padding: 0;
  }

  &.hw28 {
    height: 28px;
    width: 28px;
  }

  &:hover {
    background-color: #f0f2f4;
    color: #3a4669;
  }

  &:focus,
  &.sqr:focus {
    color: #3a4669;
    outline: solid 1px #7794e4;
  }

  &.sqr {
    padding: 10px;
  }

  &:disabled {
    color: #a4a8b1;
  }

  &-svg-icon {
    padding: 0;
    background: none;
    height: auto;
  }

  &-default {
    &-auth {
      height: 48px;
    }

    border-radius: 4px;
    background-color: #578ef7;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 12px 16px;

    &:hover,
    &:active {
      background-color: #3c6ecd;
      color: #fff;
    }

    &:focus {
      outline: solid 1px #c4c7cf;
      background-color: #578ef7;
    }

    &:disabled {
      color: #ffffff;
      background-color: #a4a8b1;
    }
  }
}

.protean-files-page {
  .cabinet-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .protean-breadcrumbs-main {
    padding-top: 8px !important;
    padding-bottom: 8px !important;

    .protean-e-cabinet-breadcrumbs {
      height: auto;

      .breadcrumb {
        div {
          display: flex;
          margin: 4px 0;

          .protean-btn {
            padding: 8px;
            line-height: normal;
            height: 31px !important;
          }
        }
      }
    }
  }

  .protean-file-table {
    div {
      display: flex;
      flex: 1;
    }

    flex-direction: column;

    .protean-file-table-head {
      height: 32px;
      border-radius: 4px;
      background-color: #f7f9fa;

      .head-th {
        &.id {
          flex: 10%;
        }

        padding-left: 16px;
        align-items: center;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #616674;
        flex: 35%;

        &.options {
          flex: 20%;
        }
      }
    }

    .protean-file-table-body {
      flex-direction: column;

      .protean-file-table-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 0 0 1;
        padding-bottom: 16px;

        .body-td {
          &.options {
            flex: 0 0 5%;
          }

          &.id {
            flex: 10%;
            padding-right: 0;
            max-width: 10%;
          }

          &.expanded {
            flex: 1 1 100%;
            max-width: 100%;
            flex-direction: column;

            .last_modified {
              margin-bottom: 16px;
            }

            .last_modified,
            .options {
              display: flex;
              flex-direction: row;
              flex: 30%;

              .expanded-title {
                flex: 40%;
              }

              .expanded-val {
                flex: 60%;
              }
            }
          }

          flex: 0 0 35%;
          max-width: 35%;
          padding: 16px 16px 0 16px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #24272a;

          .folder-link {
            word-break: break-word;
            max-width: 99%;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;
          }

          .svg-fill-icon {
            margin-bottom: 2px;
            fill: #616674;

            path {
              fill: #616674;
            }

            g {
              fill: #616674;
            }

            &.image {
              fill: none;

              g {
                fill: none;

                path {
                  &:nth-child(2) {
                    fill: #616674;
                  }

                  fill: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.card.card-tems {
  padding: 32px;

  .card-header {
    position: relative;
    padding: 0;
    margin: 14px 0;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;

    .last-updated {
      position: absolute;
      bottom: -32px;
      right: 0;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #616674;
    }
  }

  .card-body {
    margin-top: 32px;

    .item-title {
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
      margin: 32px 0;
    }

    .item-sub-title {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: justify;
      color: #616674;
      margin-bottom: 4px;
    }

    .terms-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: justify;
      color: #616674;
    }

    .terms-inner-text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: justify;
      color: #616674;
      margin-left: 24px;
    }

    .terms-link {
      color: #616673;

      &:hover {
        color: #1d1d1d;
        text-decoration: underline;
      }
    }
  }
}

.modal-base-old {
  width: 100%;
  height: 100vh;
  padding: 12px;
  top: 0;
  left: 0;
  position: absolute;
  display: none;
  background-color: rgba(36, 39, 42, 0.3);

  &.show {
    display: flex;

    .protean-modal-old {
      border-radius: 4px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px #eaeeef;
      background-color: #ffffff;
      width: 100%;
      height: 100%;

      &.show {
        display: flex;
        flex-direction: column;

        .protean-modal-header {
          display: flex;
          justify-content: space-between;

          span {
            margin-top: 16px;
            margin-left: 16px;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #24272a;
          }
        }

        .protean-modal-content {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;

          .pdf-viewer {
            display: flex;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

.fixed {
  position: fixed;
  width: -webkit-fill-available;
}

.appearance-none {
  -webkit-appearance: none;
}

.Toastify__close-button.Toastify__close-button--info {
  display: none;
}

.protean-sidebar {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05) !important;
  border: solid 1px #eaeeef !important;
  background-color: #fff;
  z-index: 9999 !important;
  height: 100vh;

  .notifications {
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #24272a;

    &.show {
      height: calc(100vh - 48px);
      overflow-y: auto;
    }

    @media (min-width: 768px) {
      .notification-link {
        span {
          display: none;
        }
      }
    }

    .notification-link {
      padding: 0.75rem 1.25rem;
      text-align: left;
    }

    .line-divider {
      height: 1px;
      width: auto;
      background-color: #eaeeef;
      margin-left: 25px;
    }

    .notifications-block {
      padding: 16px;

      .buttons {
        display: flex;
        flex-direction: column;

        .reset-btn {
          width: fit-content;
          padding: 8px 0;

          &.back-btn {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #24272a;

            i {
              color: rgba(51, 51, 51, 0.8);
              margin-right: 16px;
            }
          }

          &.mark-all-btn {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #3f66d4;
            margin-bottom: 14px;

            &:hover {
              color: #3c6ecd;
            }

            &:disabled {
              color: #a4a8b1;
            }
          }
        }
      }

      .list-notification {
        padding-bottom: 16px;
        min-height: calc(100vh - 200px);

        &.no-data {
          height: calc(100vh - 200px);

          .no-data {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 21px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616674;
          }
        }

        .notification-item {
          display: flex;
          flex-direction: row;
          margin-bottom: 32px;

          .comment-indicator {
            margin-top: -4px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.unread:hover {
              background-color: #eaeeef;

              .unread {
                background-color: transparent;
              }
            }

            .crc-indicator {
              width: 10px;
              height: 10px;
              background-color: #3f66d4;
              border: 1px #3f66d4 solid;
              border-radius: 50%;

              &.read {
                background-color: transparent;
              }
            }
          }

          .media-body {
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #24272a;

            .time-ago {
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #a4a8b1;
            }

            .extra-info {
              .wo-label {
                width: fit-content;
                margin: 12px 0;
                padding: 4px 8px;
                border-radius: 4px;
                background-color: #f7f9fa;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #616674;

                .wo-link {
                  margin-left: 8px;
                  font-size: 14px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: right;
                  color: #3f66d4;
                  //color: #a4a8b1;
                }
              }

              .wo-site {
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #616674;
              }
            }
          }
        }
      }

      .filter-items {}
    }
  }

  .sidebar-content.hide {
    //visibility: hidden;
    display: none;
  }

  .sidebar-mobile-toggler {
    background-color: transparent !important;
    border: unset;

    .sidebar-mobile-main-toggle,
    .sidebar-mobile-expand {
      background-color: transparent;
    }
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 28px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  max-height: 665px;

  h1 {
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #3a4669;
  }

  svg {
    width: 80%;
    height: auto;
    margin: auto;
  }

  .protean-btn {
    width: fit-content;
    display: flex;
    justify-self: center;
    margin: 20px auto 0;
  }
}

.nonAuthBlock {
  width: 100%;
  height: 100vh;
  padding: 50px;
  background-color: #283049;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .error-block {
    margin: auto;
    width: 60%;
  }
}

.btn-retun-back {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #578ef7;
  text-decoration: none;
}

.comming_soon {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #ffffff;
  padding: 34px 0;
  margin-bottom: 39px;

  h4 {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #24272a;
  }

  .disclaimer {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #616674;
  }
}

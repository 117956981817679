.site-atg-option-client {
  color: #a4a8b1;
  margin-left: 4px;
}

#range-picker-input-icon {
  filter: opacity(0.6);
}

.indicator {
  svg {
    fill: #616674;
  }
}
.custom-select-scrollbar {
  margin-right: 8px;
  &::-webkit-scrollbar {
    width: 3px;
    height: 56px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background-color: #f0f2f4;
  }
}

.info-btn-tooltip {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 24px;
    height: 24px;
  }
}

.commands-filter {
  position: relative;
  width: 100%;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  svg {
    position: absolute;
    top: 11px;
    height: 24px;
    width: 24px;
    right: 10px;
  }
}
.setup-header {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24272a;
  padding: 8px 20px 22px 20px;
}
.command-select {
  height: 40px;
  border-radius: 4px;
  background-color: #f7f9fa;
  display: flex;
  padding: 0 12px 0 16px;
  align-items: center;
  width: 60%;
  margin-right: 8px;
  position: relative;
  input[type='text'] {
    &:placeholder {
      color: #a4a8b1;
    }
    left: 0;
    position: absolute;
    height: inherit;
    width: 100%;
    padding: 7.7px 11.7px 7.7px 16px;
    border-radius: 4px;
    border: solid 1px #7a86a9;
    background-color: #fff;
    font-size: 13px;
    font-weight: 500;
    color: #24272a;
  }
  &.open {
    width: -webkit-fill-available;
    /* width: calc(100% + 40px); */
    &.categories {
      width: 60%;
    }

    z-index: 9999;
    .active {
      position: static;
      z-index: 9999;
      margin-left: auto;
      rotate: 180deg;
    }
  }
  &.disabled-select {
    opacity: 0.6;
    pointer-events: none;
  }
  .command-select-placeholder {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    color: #3a4669;
    display: flex;
    margin-right: auto;
  }
}

#react-select-2-input {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #24272a;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.setup-modal {
  .main-block {
    .setup-header {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      padding: 20px;
    }
    .separator {
      width: -webkit-fill-available;
      height: 3px;
      margin: 20px 16px;
      background-color: #f7f9fa;
    }
    .setup-sub-header {
      font-size: 15px;
      font-weight: normal;
      color: #24272a;
    }
    .protean-scroll {
      max-height: 350px;
      overflow-y: auto;
    }
    .success {
      padding: 0 20px 20px 20px;
      justify-content: center;
      svg {
        width: 34px;
        height: 34px;
        path:last-of-type {
          fill: #7fac16;
        }
      }
    }
    .fail {
      padding: 0 20px 20px 20px;
      justify-content: center;
      svg {
        width: 34px;
        height: 34px;
        path:last-of-type {
          fill: #d96161;
        }
      }
    }
    .setup-form {
      .setup-form-text-input {
        height: 38px;
        border-radius: 4px;
        border: solid 1px #eaeeef;
        background-color: #fff;
        width: 100%;
        padding-left: 8px;
        &.setup-form-input-error {
          background-color: #fce4e4;
          border: 1px solid #cc0033;
          outline: none;
        }
      }
      .setup-error-text {
        color: #cc0033;
      }
      padding: 0 20px;
      .date-time-input {
        justify-content: space-between;

        flex-wrap: wrap;
        .date-custom-styles {
          padding: 0 !important;
          margin-bottom: 16px;
        }
      }
      .setup-form-label {
        font-size: 14px;
        color: #616674;
      }
    }
    .atg-modal-btn-group {
      padding: 20px 20px 32px 20px;
      display: flex;
      button {
        width: 49%;
      }
      justify-content: space-between;
    }
    .info {
      .setup-value {
        font-size: 14px;
        color: #616674;
      }
      .nested {
        padding: 0 20px;
      }
    }
  }
}

.command-select-modal {
  width: 100%;
  position: absolute;
  padding: 20px 0 24px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eaeeef;
  background-color: #f7f9fa;
  display: none;
  margin: 0 29px;
  &.open {
    left: 0;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 285px;
  }
  .command-select-no-data {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #3a4669;
    margin: 0 auto;
  }
  .command-select-item {
    .dropdown-item {
      outline: none !important;
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      white-space: break-spaces;
      align-items: flex-start;
      input {
        opacity: 0.2;
        &:checked {
          opacity: 1;
        }
      }
      .atg-dropdown-span {
        &.code-span {
          margin-right: 8px;
          font-size: 13px;
          font-weight: normal;
          line-height: 1.23;
          color: #3a4669;
        }
        font-family: Roboto;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #3a4669;
      }
      .command-checkbox {
        margin-right: 8px;
      }
    }
  }
}

.base-modal {
  &.run-modal {
    height: inherit;
    .connection-setup {
      .nav-tabs {
         margin-bottom: 0;
      }
      .title-value, .title {
        margin-bottom: 8px;
       }
       .info {
        margin-top: 8px;
       }
    }
    .card-title {
      font-weight: 500;
    }
    .info {
      padding-bottom: 2px;
    }
    .buttons {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      button {
        width: 47%;
      }
    }
    .inner-title {
      display: block;
      color: #283049;
      font-size: 13px;
      font-weight: 500;
    }
    .inner-sub {
      color: #a4a8b1;
      font-size: 13px;
      display: inline-block;
    }
    .info {
      max-height: 384px;
      overflow-y: auto;
      .buttons {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        button {
          width: 47%;
        }
      }
      .title {
        background-color: #fff;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #24272a;
        position: sticky;
        top: 0;
        z-index: 9;
      }
      .info-block{
        display: flex;
        flex-wrap: wrap;
        &.block-command,.block-result {
          flex: 1 0 250px;
        }
      }
      .diagnose {
          flex: 1 0 100px;

      }
      .info-value {
        flex: 1 0 150px;
        &.diagnose {
          flex: 1 0 100px;
        }
        &.connection {
          flex: 1 0 40px;
          display: grid;
          .title-value, .value {
            margin-right: 0;
            margin-bottom: 8px;
            justify-self: center;
          }
        }
        &.command {
          flex: 1 0 180px;
        }
        .site{
          flex: 1 0 200px
        }
        &.results {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .value-block {
            width: 100%;
            display: flex;
            .value {
              /* &.status { */
              display: flex;
              align-items: center;
              margin-right: 16px;
              /* } */
              button {
                height: 32px;
                width: 32px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                &.disabled {
                  opacity: 0;
                  pointer-events: none;
                }
                .disabled-action {
                  position: relative;
                  .icon {
                    opacity: 0.3;
                  }
                  .spinner {
                    position: absolute;
                    left: 6px;
                    top: 6px;
                  }
                }
              }

              display: flex;
              flex: 1;
              &.command {
                flex: 2;
              }
            }
          }
          .title-value {
            flex: 1;
            &.command {
              flex: 2;
            }
          }
        }
        margin-top: 23px;
        .title-value {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #a4a8b1;
          margin-right: 10px;
          display: block;
        }
        .value {
          &-block {
            margin-top: 16px;
          }
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #616674;
        }
      }
      .block-result {
        .connection {
          .value, .title-value {
            justify-self: left;
          }
        }
      }
    }
    .nav-tabs-bottom .nav-link.active:before {
      background-color: #578ef7; 
    }
    .nav-tabs-bottom .nav-item {
      display: flex;
    }
    .nav-link {
      padding-bottom: 0;
    }
    .header-cell {
      font-size: 13px;
      color: #a4a8b1;
    }
  }
  &.atg-modal {
    .card-body {
      .field-title {
        font-size: 13px;
        font-weight: 500;
        color: #a4a8b1;
      }
      .buttons {
        margin-top: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          width: 47%;
        }
      }
    }
  }
}

.protean-atg {
  margin-bottom: 40px;
  padding: 34px 0 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaeeef;
  background-color: #fff;
  .page-description {
    margin: 0 8px 16px 8px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #24272a;
  }
  .devider {
    margin: 32px 0;
    height: 5px;
    background-color: #f7f9fa;
  }
  .facility-select {
    padding: 0 8px;
    .select-title {
      font-size: 13px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: #a4a8b1;
    }
    .buttons {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .protean-btn {
        &:first-of-type {
          margin-right: 4px;
        }
        &:last-of-type {
          margin-left: 4px;
        }
        width: -webkit-fill-available;
        max-width: 50%;
      }
    }
  }
  .exc-row {
    padding: 0 8px;
    display: flex;
    margin-bottom: 28px;
  }
  .table-row {
    display: flex;
    .filter {
      display: flex;
      .icon {
        padding: 5px;
        margin: 0 8px;
      }
    }
  }
  .atg-page-header {
    margin-bottom: 32px;
    padding: 0 16px;
    .title {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      margin-bottom: 16px;
    }
    .sub-title {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
    }
  }
  .facility-info {
    margin-top: 32px;
    .facility-header {
      display: flex;
      border-radius: 4px;
      background-color: #f7f9fa;
      font-size: 12px;
      font-weight: bold;
      color: #616674;
      .value {
        flex: 2;
        &:last-of-type {
          flex: 1 1 20%;
        }
        padding: 7px 15px;
      }
    }
    .facility-body {
      display: flex;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      .value {
        flex: 2;
        &:last-of-type {
          flex: 1 1 20%;
        }
        padding: 20px 15px 0 15px;
      }
    }
  }
  .no-facility {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #24272a;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100px;
  }
  .sub-row {
    display: none;
    &.open {
      display: flex;
    }
    border-top: 1px #eaeeef solid;
    margin: 0 16px;

    flex-wrap: wrap;
    .title {
      margin-top: 21px;
      width: 40%;
      font-size: 12px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #616674;
      align-items: center;
      display: flex;
    }
    .value {
      margin-top: 21px;
      width: 60%;
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #24272a;
      align-items: center;
      display: flex;
      &.buttons {
        display: flex;
        .generate-btn {
          height: 32px;
          &.active {
            background-color: #86aefb;
            height: 32px;
            color: #fff;
            cursor: default;
            display: flex;
            padding: 0 16px;
            align-items: center;
            .loading-indicator {
              animation: rotation 1s linear infinite;
              margin-right: 8px;
            }
          }
        }
        .rerun {
          height: 32px;
          padding: 0 inherit;
          display: flex;
          align-items: center;
        }

        .view,
        .download {
          padding: 0;
          margin: 0 4px;
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg > g > path {
            fill: #616674;
          }
        }
      }
    }
  }
  .extra {
    margin-bottom: 17px;
    .pagination-size {
      width: 80px;
      margin-left: auto;
      margin-right: 35px;
      align-items: center;
    }
  }
  .row-devider {
    width: 100%;
    border-bottom: 1px #eaeeef solid;
  }
  .value-row {
    display: flex;
    font-family: Roboto;
    font-size: 13px;
    line-height: normal;
    color: #24272a;
    padding: 16px 0px;
    .value {
      .expand-btn {
        width: 28px;
        height: 28px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        path {
          fill: #616674;
        }
      }
      &.command {
        padding-left: 16px;
        flex: 6;
      }

      flex: 3;
      &.code {
        flex: 1;
      }
      &.actions {
        flex: 2;
      }

      display: flex;
      padding: 0 8px;
      align-items: center;
      &.hide {
        flex: 1;
      }
    }
  }
  .product-header {
    height: 32px;
    /* padding: 9px 94px 9px 43px; */
    border-radius: 4px;
    background-color: #f7f9fa;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: #616674;
    display: flex;
    align-items: center;
    .value {
      padding: 0 8px;

      &.command {
        padding-left: 16px;
        flex: 6;
      }
      &.code {
        flex: 1;
      }
      &.actions {
        flex: 2;
      }

      height: fit-content;
      &.hide {
        visibility: hidden;
        flex: 1;
      }
      flex: 3;
    }
  }
  .diagnostic-button {
    display: flex;
    padding: 8px 8px 12px 8px;
    margin-top: 24px;
  }
}
